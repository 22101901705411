import { Button, Spinner } from 'react-bootstrap';

const SubmitButton = ( { submitButton } ) => (
    <div className="d-grid gap-2">
        <Button variant="primary" type="submit" onClick={ submitButton.handler } disabled={ submitButton.isLoading }>
            { submitButton.isLoading &&
                <Spinner
                    className="me-2"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
            }
            { submitButton.text }
        </Button>
    </div>
);

export default SubmitButton;