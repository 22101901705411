import React, { useEffect, useState } from "react";
import { useForm } from "../../util/common";
import { withRouter } from "react-router";
import { useHistory, useParams } from "react-router-dom";
import {
  RequestForm,
  ViewPrompt,
  EditFormWrap,
  StudentView,
} from "../../components";
import {
  useSelector,
  useDispatch,
  networkStatus,
  networkAction,
  teachersAction,
  myRequestsThunk,
  selectMyRequestById,
  selectStudentRequestById,
  appAction,
  onTeacherSessionsThunk,
  constant,
} from "@mainnote/coremedalteacher";

const { requestStatus } = constant;

const RequestPage = () => {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const history = useHistory();
  const [modalShow, setModalShow] = useState(false);
  const [studentModalShow, setStudentModalShow] = useState(null);
  const dispatch = useDispatch();

  const isMyRequest = history?.location?.state?.isMyRequest;
  const isAuth = !!useSelector((state) => state.app.auth.jwt);
  const status_my = useSelector((state) => state.teachers.onMyRequests.status);
  const errors_my = useSelector((state) => state.teachers.onMyRequests.errors);
  const status_student = useSelector(
    (state) => state.app.onTeacherSessions.status
  );
  const errors_student = useSelector(
    (state) => state.app.onTeacherSessions.errors
  );
  const status = isMyRequest ? status_my : status_student;
  const errors = isMyRequest ? errors_my : errors_student;

  let { requestId } = useParams();
  if (typeof requestId === "string") requestId = parseInt(requestId);

  const myRequest = useSelector((state) =>
    selectMyRequestById(state, requestId)
  );
  const studentRequest = useSelector((state) =>
    selectStudentRequestById(state, requestId)
  );
  const request = isMyRequest ? myRequest : studentRequest;
  let initForm = {};
  const {
    // form,
    // setForm,
    // formErrors,
    // setFormErrors,
    submitButton,
    deleteButton,
    // setFormField,
  } = useForm({ initForm, status });
  const heading =
    request?.requestStatus === requestStatus.accepted
      ? isMyRequest
        ? "Cancel Request"
        : "Reject Request"
      : isMyRequest
      ? "Remove Request"
      : "Reject Request";
  const bodyHeader =
    request?.requestStatus === requestStatus.accepted
      ? isMyRequest
        ? "Are you sure you want to cancel this request?"
        : "Are you sure you want to reject this request?"
      : isMyRequest
      ? "Are you sure you want to remove this request?"
      : "Are you sure you want to reject this request?";
  const body =
    request?.requestStatus === requestStatus.accepted
      ? isMyRequest
        ? "This request will be reviewed and canceled."
        : "This request will be rejected"
      : isMyRequest
      ? "This request will be removed from your account."
      : "This request will be rejected";

  // handlers
  const goBack = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Accecpt the request from student
    dispatch(
      onTeacherSessionsThunk({
        action: networkAction.put,
        requestId: request.id,
        teacherSessionId: request?.teacher_session?.id,
        studentId: request?.student?.id,
        status: requestStatus.accepted,
      })
    );
  };
  submitButton.text = "Accept";
  submitButton.handler = handleSubmit;

  const handleDelete = (e) => {
    e.preventDefault();
    setModalShow(true);
  };

  if (request?.requestStatus === requestStatus.accepted) {
    if (isMyRequest) {
      deleteButton.text = "Cancel";
    } else {
      deleteButton.text = "Reject";
    }
  } else {
    if (!isMyRequest) {
      deleteButton.text = "Reject";
    } // else "delete"
  }
  deleteButton.handler = handleDelete;

  const removeMyRequest = () => {
    if (request) {
      if (request.requestStatus === requestStatus.accepted) {
        dispatch(
          myRequestsThunk({
            requestId,
            action: networkAction.put,
            status: requestStatus.canceled,
          })
        );
      } else {
        dispatch(myRequestsThunk({ requestId, action: networkAction.delete }));
      }
    }
    setModalShow(false);
  };
  const rejectStudentRequest = () => {
    if (request) {
      // if (request.requestStatus === requestStatus.accepted) {
      //   // Cancel the request of student
      //   dispatch(
      //     onTeacherSessionsThunk({
      //       action: networkAction.put,
      //       requestId: request.id,
      //       teacherSessionId: request?.teacher_session?.id,
      //       studentId: request?.student?.id,
      //       status: requestStatus.canceled,
      //     })
      //   );
      // } else {
      // Reject the request of student
      dispatch(
        onTeacherSessionsThunk({
          action: networkAction.put,
          requestId: request.id,
          teacherSessionId: request?.teacher_session?.id,
          studentId: request?.student?.id,
          status: requestStatus.rejected,
        })
      );
      // }
    }
    setModalShow(false);
  };
  const viewStudent = (student) => setStudentModalShow(student);

  // fields
  const fields = {
    request: request,
  };

  // effects
  // on page load
  useEffect(() => {
    if (isPageLoading) {
      // If no fromPath, redirect to schedule
      if (!history?.location?.state?.fromPath) history.push("/schedule");

      // reset errors
      if (isAuth) {
        // todo: load requests again
        dispatch(teachersAction.resetErrors({ key: "onMyRequests" }));
        dispatch(appAction.resetErrors({ key: "onTeacherSessions" }));
      }
      setIsPageLoading(false);
    }
  }, [isAuth, isPageLoading, dispatch, history]);

  // on submit success
  useEffect(() => {
    if (!isPageLoading && status === networkStatus.succeeded) {
      if (history?.location?.state?.fromPath) {
        history.push(history.location.state.fromPath, { fromPath: "/request" });
      } else {
        history.push("/");
      }
    }
  }, [status, history, isPageLoading]);

  // on unmounting
  useEffect(() => {
    return () => {
      dispatch(teachersAction.resetErrors({ key: "onMyRequests" }));
    };
  }, [dispatch]);

  const pageTitle = `Request Detail (${request?.requestStatus})`;

  // rendering
  return (
    <EditFormWrap
      title={pageTitle}
      errors={errors}
      goBack={goBack}
      messagePromptProps={{
        show: modalShow,
        heading,
        bodyHeader,
        body,
        cancleHandler: () => setModalShow(false),
        okHandler: isMyRequest ? removeMyRequest : rejectStudentRequest,
      }}
      description={""}
      entityId={requestId}
      entity={request}
      notFoundMessage={"Requesting appointment not found!"}
    >
      <RequestForm
        fields={fields}
        entityId={requestId}
        entity={request}
        submitButton={
          isMyRequest || request?.requestStatus !== requestStatus.pending
            ? false
            : submitButton
        }
        deleteButton={
          request?.requestStatus === requestStatus.pending ||
          request?.requestStatus === requestStatus.accepted
            ? deleteButton
            : null
        }
        viewStudent={viewStudent}
      />
      <ViewPrompt
        show={!!studentModalShow}
        heading="Student"
        cancleHandler={() => setStudentModalShow(null)}
      >
        <StudentView student={studentModalShow} />
      </ViewPrompt>
    </EditFormWrap>
  );
};

export default withRouter(RequestPage);
