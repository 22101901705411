import { FormControl, InputGroup } from "react-bootstrap";
import styles from "./css/SearchBar.module.css";
import { IoHome, IoSearch } from "react-icons/io5";

const SearchBar = ({
  placeholder = "",
  value = "",
  onClick = () => {},
  handleSearchChange = () => {},
  handleSearchClick = () => {},
  handleOnClickHome = () => {},
  icon = false,
  disabled = false,
  inputRef = null,
}) => {
  const onChange = (event) => {
    event.preventDefault();
    handleSearchChange(event.target.value);
  };

  const onKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchClick();
    }
  };
  const onClickHome = (event) => {
    event.preventDefault();
    handleOnClickHome();
  };
  return (
    <InputGroup className={"mb-3"} onClick={onClick}>
      <InputGroup.Text className={styles.btn} onClick={onClickHome}>
        {icon || <IoHome />}
      </InputGroup.Text>
      <FormControl
        placeholder={placeholder}
        aria-label="search-bar"
        aria-describedby="search-bar"
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
        disabled={disabled}
        ref={inputRef}
      />
      <InputGroup.Text className={styles.btn} onClick={handleSearchClick}>
        <IoSearch />
      </InputGroup.Text>
    </InputGroup>
  );
};

export default SearchBar;
