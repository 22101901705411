import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import styles from './css/UploadFile.module.css';
import { Container } from 'react-bootstrap';

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

const UploadFile = ( {
    files = [],
    onDrop = () => { },
    dropzoneProps = { accept: 'image/*' }
} ) => {

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone( { onDrop, ...dropzoneProps } );

    const style = useMemo( () => ( {
        ...( isDragActive ? activeStyle : {} ),
        ...( isDragAccept ? acceptStyle : {} ),
        ...( isDragReject ? rejectStyle : {} )
    } ),
        [
            isDragActive,
            isDragReject,
            isDragAccept
        ] );

    const thumbs = files.map( file => (
        <div className={ styles.thumb } key={ file.name }>
            <div className={ styles.thumbInner }>
                <img
                    src={ file.preview }
                    className={ styles.img }
                    alt={ file.name }
                />
            </div>
        </div>
    ) );

    return (
        <Container>
            <div className={ styles.root } { ...getRootProps( { style } ) }>
                <input { ...getInputProps() } />
                {
                    isDragActive ?
                        <p>Drop the files here ...</p> :
                        <p>Drag 'n' drop some files here, or click to select files</p>
                }
            </div>
            <aside className={ styles.thumbsContainer }>
                { thumbs }
            </aside>
        </Container>
    );
};

export default UploadFile;