import { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { Container, ListGroup } from "react-bootstrap";
import {
  useSelector,
  useDispatch,
  teachersAction,
  myRequestsThunk,
  appAction,
  onTeacherSessionsThunk,
  selectSessionsInDate,
} from "@mainnote/coremedalteacher";
import { goToAuthPage } from "../../util/common";
import { isEmpty } from "lodash";
import {
  DayEvents,
  MessagePrompt,
  ViewPrompt,
  StudentView,
  SessionDisplayItem,
  EmptyList,
} from "../../components";

const SchedulePage = () => {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const [chosenTeacherSession, setChosenTeacherSession] = useState(null);
  const [studentModalShow, setStudentModalShow] = useState(null);

  // states
  const isReload = useSelector((state) => state.app.isReload.schedule);
  const isAuth = !!useSelector((state) => state.app.auth.jwt);

  const sessionsInDate = useSelector((state) => selectSessionsInDate(state));

  // handlers
  const goToLoginPage = goToAuthPage(
    history,
    dispatch,
    appAction,
    "/login",
    "/schedule",
    "login"
  );
  const showSessionDetail = (teacherSession) => {
    setChosenTeacherSession(teacherSession);
    setModalShow(true);
  };
  const viewStudent = (student) => setStudentModalShow(student);

  // effects
  // on page load
  useEffect(() => {
    if (isPageLoading || isReload) {
      if (isAuth) {
        dispatch(teachersAction.resetErrors({ key: "onMyRequests" }));
        dispatch(myRequestsThunk());
        dispatch(appAction.resetErrors({ key: "onTeacherSessions" }));
        dispatch(onTeacherSessionsThunk());
      }
      // reset reload flag
      dispatch(
        appAction.setStateByPath({ path: "isReload.schedule", value: false })
      );

      setIsPageLoading(false);
    }
  }, [isAuth, isPageLoading, isReload, dispatch]);

  // rendering

  return (
    <Container className="page-body">
      {!isEmpty(sessionsInDate) ? (
        <ListGroup>
          {sessionsInDate.map((events, i) => (
            <DayEvents key={i} events={events} onClick={showSessionDetail} />
          ))}
        </ListGroup>
      ) : (
        <EmptyList
          goToLoginPage={goToLoginPage}
          isAuth={isAuth}
          textWithAuth={"There is no upcoming sessions."}
          textWithOutAuth={"Login your account now."}
        />
      )}
      <MessagePrompt
        show={modalShow}
        heading={"Session Detail"}
        content={
          <SessionDisplayItem
            teacherSession={chosenTeacherSession}
            isViewOnly={true}
            viewStudent={viewStudent}
          />
        }
        cancleHandler={() => setModalShow(false)}
        isDisplay={true}
      />
      <ViewPrompt
        show={!!studentModalShow}
        heading="Student"
        cancleHandler={() => setStudentModalShow(null)}
      >
        <StudentView student={studentModalShow} />
      </ViewPrompt>
    </Container>
  );
};

export default withRouter(SchedulePage);
