import { memo } from "react";
import { isEqual } from "lodash";
import { ListGroup } from "react-bootstrap";
import CourseItem from "./CourseItem";

const CourseListItem = (props) => {
  return (
    <ListGroup.Item className="d-flex justify-content-center">
      <CourseItem {...props} />
    </ListGroup.Item>
  );
};

const isDataEqual = (prevProps, nextProps) => {
  return (
    isEqual(prevProps.course, nextProps.course) &&
    isEqual(prevProps.selected, nextProps.selected)
  );
};

export default memo(CourseListItem, isDataEqual);
