import styles from "./css/TwoRowsItem.module.css";
import { ListGroup, Form } from "react-bootstrap";

const TwoRowsItem = ({ title, detail, onClick = () => {} }) => {
  return (
    <ListGroup.Item action className={styles.root} onClick={onClick}>
      <Form.Label className={styles.title}>{title}</Form.Label>
      <Form.Text className={styles.detail} muted>
        {detail}
      </Form.Text>
    </ListGroup.Item>
  );
};

export default TwoRowsItem;
