import React, { useEffect, useState } from "react";
import { useForm, useFiles } from "../../util/common";
import StudentProfileForm from "../../components/StudentComponent/StudentProfileForm";
import {
  useSelector,
  useDispatch,
  onStudentThunk,
  networkStatus,
  networkAction,
  appAction,
  cache,
  selectStudentById,
} from "@mainnote/coremedalteacher";
import { withRouter } from "react-router";
import { useHistory, useParams } from "react-router-dom";
import EditFormWrap from "../../components/FormControlComponent/EditFormWrap";

const getFormValue = (entity) => ({
  studentId: entity.id,
  firstName: entity.firstName,
  middleName: entity.middleName,
  lastName: entity.lastName,
  biography: entity.biography,
  introduction: entity.introduction,
});

const StudentProfilePage = () => {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const history = useHistory();
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();

  const isAuth = !!useSelector((state) => state.app.auth.jwt);
  const status = useSelector((state) => state.app.onStudents.status);
  const errors = useSelector((state) => state.app.onStudents.errors);
  let { studentId } = useParams();
  if (typeof studentId === "string") studentId = parseInt(studentId);

  const student = useSelector((state) => selectStudentById(state, studentId));

  let initForm = {};
  if (isPageLoading && student) {
    initForm = getFormValue(student);
  }

  const { files, onDrop } = useFiles({ cacheItem: cache.student });
  const {
    form,
    setForm,
    formErrors,
    setFormErrors,
    submitButton,
    deleteButton,
    setFormField,
  } = useForm({ initForm, status });
  const findFormErrors = () => {
    const { firstName, introduction } = form;
    const newErrors = {};
    // firstName is required
    if (!firstName || firstName === "")
      newErrors.firstName = "First Name is required!";
    // introduction is required
    if (!introduction || introduction === "")
      newErrors.introduction = "Introduction is required!";

    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // reset errors
    dispatch(appAction.resetErrors({ key: "onStudents" }));

    // get our new errors
    const newErrors = findFormErrors();
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setFormErrors(newErrors);
    } else {
      // No errors! Put any logic here for the form submission!
      dispatch(
        onStudentThunk({
          action: networkAction.post,
          id: form.studentId,
          firstName: form.firstName,
          middleName: form.middleName,
          lastName: form.lastName,
          biography: form.biography,
          introduction: form.introduction,
        })
      );
    }
  };
  submitButton.handler = handleSubmit;

  const handleDelete = (e) => {
    e.preventDefault();
    setModalShow(true);
  };
  deleteButton.handler = handleDelete;

  const removeStudent = () => {
    if (student) {
      dispatch(onStudentThunk({ action: networkAction.delete, id: studentId }));
    }
    setModalShow(false);
  };
  const goBack = (e) => {
    e.preventDefault();
    history.goBack();
  };

  // fields
  const fields = {
    firstName: {
      controlId: "firstNameStudentProfileForm",
      label: "First Name (required)",
      feedback: { text: formErrors.firstName, type: "invalid" },
      placeholder: "Enter student's first name",
      value: form.firstName || "",
      onChange: (e) => setFormField("firstName", e.target.value),
      isInvalid: !!formErrors.firstName,
    },
    middleName: {
      controlId: "middleNameStudentProfileForm",
      label: "Middle Name",
      feedback: { text: formErrors.middleName, type: "invalid" },
      placeholder: "Enter student's middle name",
      value: form.middleName || "",
      onChange: (e) => setFormField("middleName", e.target.value),
      isInvalid: !!formErrors.middleName,
    },
    lastName: {
      controlId: "lastNameStudentProfileForm",
      label: "Last Name",
      feedback: { text: formErrors.lastName, type: "invalid" },
      placeholder: "Enter student's last name",
      value: form.lastName || "",
      onChange: (e) => setFormField("lastName", e.target.value),
      isInvalid: !!formErrors.lastName,
    },
    profilePicture: {
      controlId: "profilePictureStudentProfileForm",
      label: "Profile Picture",
      feedback: { text: formErrors.profilePicture, type: "invalid" },
      value: student,
      files,
      onDrop,
      isInvalid: !!formErrors.profilePicture,
    },
    biography: {
      controlId: "biographyStudentProfileForm",
      label: "Biography",
      feedback: { text: formErrors.biography, type: "invalid" },
      placeholder: "Enter student's biography",
      value: form.biography || "",
      onChange: (e) => setFormField("biography", e.target.value),
      isInvalid: !!formErrors.biography,
    },
    introduction: {
      controlId: "introductionStudentProfileForm",
      label: "Introduction (required)",
      feedback: { text: formErrors.introduction, type: "invalid" },
      placeholder: "Enter student's introduction",
      value: form.introduction || "",
      onChange: (e) => setFormField("introduction", e.target.value),
      isInvalid: !!formErrors.introduction,
    },
  };

  // effects
  // on page load
  useEffect(() => {
    if (isPageLoading) {
      // reset cache
      cache.student.files = [];
      // reset errors
      dispatch(appAction.resetErrors({ key: "onStudents" }));
      if (isAuth) {
        dispatch(onStudentThunk());
      }
      setIsPageLoading(false);
    }

    if (!isPageLoading && status === networkStatus.reloaded && student) {
      setForm(getFormValue(student));
      dispatch(appAction.resetErrors({ key: "onStudents" }));
    }
  }, [isPageLoading, dispatch, isAuth, setForm, student, status]);

  // on submit success
  useEffect(() => {
    if (!isPageLoading && status === networkStatus.succeeded) {
      if (history?.location?.state?.fromPath) {
        history.push(history.location.state.fromPath, { fromPath: "/student" });
      } else {
        history.push("/");
      }
    }
  }, [status, history, isPageLoading]);

  // on unmounting
  useEffect(() => {
    return () => {
      cache.student.files = [];
      dispatch(appAction.resetErrors({ key: "onStudents" }));
    };
  }, [dispatch]);

  // rendering

  return (
    <EditFormWrap
      title={studentId ? "Update Student Profile" : "Add Student Profile"}
      errors={errors}
      goBack={goBack}
      messagePromptProps={{
        show: modalShow,
        heading: "Remove Student Profile",
        bodyHeader: "Are you sure you want to remove this student profile?",
        body: "This student profile will be removed from the system.",
        cancleHandler: () => setModalShow(false),
        okHandler: removeStudent,
      }}
      description={"* Student information is only viewable by your teachers!"}
      entityId={studentId}
      entity={student}
      notFoundMessage={"Student not found!"}
    >
      <StudentProfileForm
        fields={fields}
        entityId={studentId}
        entity={student}
        submitButton={submitButton}
        deleteButton={deleteButton}
      />
    </EditFormWrap>
  );
};

export default withRouter(StudentProfilePage);
