import { AiOutlineUser } from "react-icons/ai";
import { Figure } from "react-bootstrap";
import clsx from "clsx";
import styles from "./css/Avatar.module.css";

const Avatar = ({
  url,
  alt,
  avatarStyle = {},
  iconStyle = {},
  selected = false,
  showBorder = true,
}) => (
  <>
    {url ? (
      <Figure.Image
        className={clsx(
          "rounded-circle",
          styles.avatar,
          showBorder && styles.avatarBorder,
          selected && styles.selected
        )}
        style={avatarStyle}
        alt={alt}
        src={url}
      />
    ) : (
      <AiOutlineUser
        className={clsx(
          "rounded-circle",
          styles.icon,
          selected && styles.selected
        )}
        style={iconStyle}
      />
    )}
  </>
);
export default Avatar;
