import styles from './css/IconFiveLevels.module.css';
import IconLevel from './IconLevel';

const IconFiveLevels = ( { level } ) => {
    let levels = [];
    // Base on the rate from 1 - 5, show the level accordingly
    for ( let i = 1; i <= 5; i++ ) {
        if ( i <= level ) {
            levels.push( <IconLevel key={ i } type="full" /> );
        } else {
            levels.push( <IconLevel key={ i } type="empty" /> );
        }
    }

    return ( <div className={ styles.root }>
        { levels }
    </div> );
};

export default IconFiveLevels;