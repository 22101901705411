import { isEmpty } from "lodash";
import { toLocalDateTime } from "@mainnote/coremedalteacher";
import CourseItem from "../ListGroupComponent/CourseItem";
import TeacherItem from "../ListGroupComponent/TeacherItem";
import AvatarClickableItem from "../AvatarComponent/AvatarClickableItem";
import { FormLabel, DescriptionText, RemovableBubble } from "../../elements";
import { Row } from "react-bootstrap";

const StudentViewItem = ({ entity, onClick }) => (
  <AvatarClickableItem entity={entity} onClick={() => onClick(entity)} />
);

const SessionDisplayItem = ({
  teacherSession,
  teacher: chosenTeacher,
  chosenTeacherAndCourse,
  setChosenTeacherAndCourse = () => {},
  viewStudent = () => {},
  isViewOnly = false,
  student,
}) => {
  if (!teacherSession || isEmpty(teacherSession)) {
    return <></>;
  }
  const {
    duration,
    maxAttendees,
    attendees,
    teacher,
    teacher_and_course,
    requests,
    request,
  } = teacherSession;

  const availableTimes = teacherSession.timeslots
    ?.map((timeslot) => new Date(timeslot.availableTime))
    .sort((a, b) => new Date(a) - new Date(b));
  const hourlyRate = teacher_and_course
    ? teacher_and_course.hourlyRate
    : chosenTeacherAndCourse?.hourlyRate || 0;

  return (
    <>
      {student && (
        <>
          <FormLabel>Student</FormLabel>
          <StudentViewItem entity={student} onClick={viewStudent} />
        </>
      )}
      {request?.student && (
        <>
          <FormLabel>Student</FormLabel>
          <StudentViewItem entity={request?.student} onClick={viewStudent} />
        </>
      )}
      {!student && requests && (
        <Row>
          {requests?.length > 0 &&
            requests.map((request) => (
              <StudentViewItem
                key={request.id}
                entity={request.student}
                onClick={viewStudent}
              />
            ))}
        </Row>
      )}
      <FormLabel
        className={
          teacher_and_course || isViewOnly ? "text-muted" : "text-danger"
        }
      >
        {!(teacher_and_course || isViewOnly) && "Choose "} Course
      </FormLabel>
      {teacher_and_course || isViewOnly ? (
        <CourseItem
          course={teacher_and_course?.course || chosenTeacherAndCourse?.course}
        />
      ) : (
        <>
          {chosenTeacher?.teacher_and_courses?.map((entity, index) => (
            <CourseItem
              key={index}
              course={entity.course}
              onClick={() => {
                setChosenTeacherAndCourse(entity);
              }}
              selected={entity === chosenTeacherAndCourse}
            />
          ))}
        </>
      )}
      <FormLabel>Teacher</FormLabel>
      <TeacherItem teacher={teacher} />
      <FormLabel>Available Times</FormLabel>
      <div className="mb-2">
        {availableTimes?.length > 0 &&
          availableTimes?.map((availableTime, ind) => (
            <RemovableBubble
              key={ind}
              showButton={false}
              text={toLocalDateTime(availableTime)}
            />
          ))}
      </div>
      <FormLabel>Duration</FormLabel>
      <DescriptionText>{duration} minutes</DescriptionText>
      <FormLabel>Attendees</FormLabel>
      <DescriptionText>
        {attendees} / {maxAttendees} students joined
      </DescriptionText>
      {(teacher_and_course || chosenTeacherAndCourse) &&
        availableTimes?.length > 0 && (
          <>
            <FormLabel>Total charge</FormLabel>
            {hourlyRate ? (
              availableTimes?.length > 1 ? (
                <DescriptionText>
                  {hourlyRate * availableTimes.length} dollars{" "}
                  <span className="text-muted">
                    ( ${hourlyRate} x {availableTimes.length} hours )
                  </span>
                </DescriptionText>
              ) : (
                <DescriptionText>{hourlyRate} dollars</DescriptionText>
              )
            ) : (
              <DescriptionText>Free</DescriptionText>
            )}
          </>
        )}
    </>
  );
};

export default SessionDisplayItem;
