import React from "react";
import DateTimePicker from "react-datetime-picker";
import { toDate } from "@mainnote/coremedalteacher";

const DateTimeInput = ({ value = new Date(), onChange = () => {} }) => {
  return (
    <div className="d-inline">
      <DateTimePicker onChange={onChange} value={toDate(value)} />
    </div>
  );
};

export default DateTimeInput;
