import { Container, Form, Row } from "react-bootstrap";
import SubmitButton from "../FormControlComponent/SubmitButton";
import DeleteButton from "../FormControlComponent/DeleteButton";
import SessionDisplayItem from "../ScheduleComponent/SessionDisplayItem";

const RequestForm = ({
  fields = {},
  entityId,
  entity,
  submitButton = {},
  deleteButton = {},
  viewStudent = () => {},
}) => {
  return (
    <Container>
      <Form>
        <Row>
          {fields.request && (
            <SessionDisplayItem
              teacherSession={fields.request.teacher_session}
              teacher={fields.request.teacher}
              chosenTeacherAndCourse={fields.request.teacher_and_course}
              viewStudent={viewStudent}
              isViewOnly={true}
              student={fields.request.student}
            />
          )}
        </Row>

        {/* submit button */}
        {submitButton && <SubmitButton submitButton={submitButton} />}
        {/* delete button */}
        {entityId && entity && deleteButton && (
          <DeleteButton deleteButton={deleteButton} />
        )}
      </Form>
    </Container>
  );
};

export default RequestForm;
