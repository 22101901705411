import { toLocalDateTime } from "@mainnote/coremedalteacher";
import smartTruncate from "smart-truncate";
import AvatarTextItem from "../AvatarComponent/AvatarTextItem";
import styles from "./css/RowCourseName.module.css";

const RowCourseName = ({ session }) => {
  const { availableTime, teacher_and_course } = session;
  return (
    <>
      <div className={styles.time}>
        {toLocalDateTime(availableTime, "HH:mm A")}
      </div>
      {teacher_and_course && teacher_and_course.course && (
        <AvatarTextItem
          entity={teacher_and_course.course}
          showField="medias"
          text={smartTruncate(teacher_and_course.course.courseName, 100)}
        />
      )}
    </>
  );
};

export default RowCourseName;
