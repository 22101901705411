import { Card, Row, Col } from "react-bootstrap";
import styles from "./css/TeacherItem.module.scss";
import {
  getImageThumbnail,
  findTeacherCourses,
} from "@mainnote/coremedalteacher";
import smartTruncate from "smart-truncate";
import AvatarDisplayItem from "../AvatarComponent/AvatarDisplayItem";
import { DescriptionText } from "../../elements";
import { ExternalImage } from "../../elements";
import TeacherEvaluation from "../TeacherComponent/TeacherEvaluation";

const TeacherItem = ({ teacher = {}, onClick = () => {} }) => {
  const thumbnail = getImageThumbnail(teacher?.picture);
  const thumbnailUrl = thumbnail ? thumbnail.url : "";

  return (
    <div
      className={styles.root}
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
    >
      <Card className={"border-0"}>
        <Row className={styles.row}>
          <Col xs={7} className={styles.col_body}>
            <Card.Body className={styles.card_body}>
              <Card.Title>
                {teacher.fullName ? smartTruncate(teacher.fullName, 50) : ""}
                <TeacherEvaluation
                  title={teacher?.title}
                  isCertified={teacher.isCertified}
                  rate={teacher.rate}
                />
              </Card.Title>
              <DescriptionText>
                {smartTruncate(teacher?.introduction, 120) || ""}
              </DescriptionText>
              <Row className={styles.cardBottom}>
                {findTeacherCourses(teacher.teacher_and_courses).map(
                  (entity) => (
                    <AvatarDisplayItem
                      className={styles.avatar}
                      key={entity.id}
                      size={18}
                      showField={"medias"}
                      entity={entity}
                    />
                  )
                )}
              </Row>
            </Card.Body>
          </Col>
          <Col xs={5}>
            <ExternalImage src={thumbnailUrl} />
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default TeacherItem;
