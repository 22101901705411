import AvatarDisplayItem from './AvatarDisplayItem';
import { Container, Row, Col } from 'react-bootstrap';
import styles from './css/AvatarTextItem.module.css';
import clsx from 'clsx';

const AvatarTextItem = ( { entity, text, showField, className } ) => {
    return (
        <Container className={ clsx(styles.nogap, className) } fluid >
            <Row className={ styles.nogap } >
                <AvatarDisplayItem
                    className={ clsx(styles.nogap, styles.icon) }
                    iconStyle={ { margin: 0 } }
                    size={ 10 }
                    showField={ showField }
                    entity={ entity } />
                <Col className={ clsx( "col-10", styles.nogap, styles.text ) }>{ text }</Col>
            </Row>
        </Container>
    )
};

export default AvatarTextItem;