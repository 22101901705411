import { Alert } from 'react-bootstrap';

const Error = ( { errors } ) => (
    <>
        { errors && errors.length > 0 &&
            errors.map( ( error, index ) => (
                <Alert key={ index } variant={ error.variant || "danger" }>
                    { error.message }
                </Alert>
            ) )
        }
    </>
);

export default Error;