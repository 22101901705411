import styles from './css/IconFiveStars.module.css';
import IconStar from './IconStar';

const IconFiveStars = ( { rate } ) => {
    let rates = [];
    // Base on the rate from 1 - 5, show the rate accordingly
    for ( let i = 1; i <= 5; i++ ) {
        if ( i <= rate ) {
            rates.push( <IconStar key={ i } type="full" /> );
        } else if ( i > rate && ( i - 0.5 ) <= rate ) {
            rates.push( <IconStar key={ i } type="half" /> );
        } else {
            rates.push( <IconStar key={ i } type="empty" /> );
        }
    }

    return ( <div className={ styles.root }>
        { rates }
    </div> );
};

export default IconFiveStars;