import { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { Container } from "react-bootstrap";
import {
  useSelector,
  useDispatch,
  teachersAction,
  myRequestsThunk,
  appAction,
  onTeacherSessionsThunk,
  selectMyRequestsByStatus,
  selectStudentRequestsByStatus,
} from "@mainnote/coremedalteacher";
import { goToPage, goToAuthPage } from "../../util/common";
import { isEmpty } from "lodash";
import {
  EmptyList,
  MenuListContainer,
  ListMenu,
  RequestItem,
} from "../../components";

/*
   When the student send a request to teacher, (how to send)
   the teacher's user notification request count add 1; (how to update)
   also all online teacher clients will get the notification; (emit to clients which belong to techer)
   if teacher reload the requests, reset count to 0; (reset it)
   if teacher client are offline, when they are back online, (how to check it)
   they check the user notification the same to get it.
*/
const RequestsPage = () => {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();

  // states
  const isReload = useSelector((state) => state.app.isReload.requests);
  const isAuth = !!useSelector((state) => state.app.auth.jwt);
  const myRequestsByStatus = useSelector((state) =>
    selectMyRequestsByStatus(state)
  );
  const studentRequestsByStatus = useSelector((state) =>
    selectStudentRequestsByStatus(state)
  );

  // handlers
  const goToMyRequestPage = goToPage(history, "/request", "/requests", {
    isMyRequest: true,
  });
  const goToStudentRequestPage = goToPage(history, "/request", "/requests", {
    isMyRequest: false,
  });
  const goToLoginPage = goToAuthPage(
    history,
    dispatch,
    appAction,
    "/login",
    "/requests",
    "login"
  );

  // effects
  // on page load
  useEffect(() => {
    if (isPageLoading || isReload) {
      if (isAuth) {
        dispatch(teachersAction.resetErrors({ key: "onMyRequests" }));
        dispatch(myRequestsThunk());
        dispatch(appAction.resetErrors({ key: "onTeacherSessions" }));
        dispatch(onTeacherSessionsThunk());
      }
      // reset notification and reload flag
      dispatch(
        appAction.setStateByPath({
          path: "notification.studentRequest",
          value: 0,
        })
      );
      dispatch(
        appAction.setStateByPath({ path: "isReload.requests", value: false })
      );
      setIsPageLoading(false);
    }
  }, [isAuth, isPageLoading, isReload, dispatch]);

  // rendering
  const getMenuListForRequests = (title, requests, isMyRequest) => {
    if (!requests || requests.length === 0) return <></>;

    return (
      <MenuListContainer header={title} menu={<ListMenu></ListMenu>}>
        {requests.map((request) => (
          <RequestItem
            key={request.id}
            request={request}
            onClick={isMyRequest ? goToMyRequestPage : goToStudentRequestPage}
          />
        ))}
      </MenuListContainer>
    );
  };

  return (
    <Container className="page-body">
      {!isEmpty(myRequestsByStatus) || !isEmpty(studentRequestsByStatus) ? (
        <>
          {getMenuListForRequests(
            "Pending Requests",
            myRequestsByStatus?.pending,
            true
          )}
          {getMenuListForRequests(
            "Accepted Requests",
            myRequestsByStatus?.accepted,
            true
          )}
          {getMenuListForRequests(
            "Rejected Requests",
            myRequestsByStatus?.rejected,
            true
          )}
          {getMenuListForRequests(
            "Canceled Requests",
            myRequestsByStatus?.canceled,
            true
          )}
          {getMenuListForRequests(
            "Expired Requests",
            myRequestsByStatus?.expired,
            true
          )}
          {getMenuListForRequests(
            "Adjusted Requests",
            myRequestsByStatus?.adjusted,
            true
          )}
          {getMenuListForRequests(
            "Student Requests (Pending)",
            studentRequestsByStatus?.pending,
            false
          )}
          {getMenuListForRequests(
            "Student Requests (Accepted)",
            studentRequestsByStatus?.accepted,
            false
          )}
          {getMenuListForRequests(
            "Student Requests (Rejected)",
            studentRequestsByStatus?.rejected,
            false
          )}
          {getMenuListForRequests(
            "Student Requests (Canceled)",
            studentRequestsByStatus?.canceled,
            false
          )}
          {getMenuListForRequests(
            "Student Requests (Expired)",
            studentRequestsByStatus?.expired,
            false
          )}
          {getMenuListForRequests(
            "Student Requests (Adjusted)",
            studentRequestsByStatus?.adjusted,
            false
          )}
        </>
      ) : (
        <EmptyList
          goToLoginPage={goToLoginPage}
          isAuth={isAuth}
          textWithAuth={"There is no request yet."}
          textWithOutAuth={"Login your account now."}
        />
      )}
    </Container>
  );
};

export default withRouter(RequestsPage);
