import CourseItem from "./CourseItem";
import CurrencyInput from "react-currency-input-field";
import styles from "./css/TeacherCourseItem.module.css";
import clsx from "clsx";
import { Form, Container, CloseButton } from "react-bootstrap";

const TeacherCourseItem = ({
  label,
  placeholder,
  teacher_and_course,
  onChange: _onChange = () => {},
  onDelete: _onDelete = () => {},
}) => {
  const { course, hourlyRate } = teacher_and_course;
  const onChange = (value) => {
    _onChange({ ...teacher_and_course, hourlyRate: value });
  };

  const onDelete = (e) => {
    e.preventDefault();
    _onDelete(teacher_and_course);
  };

  return (
    <Container className={styles.root}>
      <Form.Label>{label}</Form.Label>
      <CloseButton
        className={styles.closeBtn}
        onClick={onDelete}
        aria-label="Remove this course"
      />
      <CurrencyInput
        placeholder={placeholder}
        className={clsx(styles.input, "form-control")}
        allowNegativeValue={false}
        prefix="$"
        defaultValue={0}
        value={hourlyRate}
        decimalsLimit={2}
        onValueChange={onChange}
      />
      {course && <CourseItem course={course} />}
    </Container>
  );
};

export default TeacherCourseItem;
