import { ButtonGroup, Button } from 'react-bootstrap';
import styles from './css/TabBar.module.css';
import clsx from 'clsx';

const isActive = active => active ? "primary" : "outline-secondary";

const TabBar = ( { course, teacher } ) => (
    <div className={ clsx('text-center', styles.root) }>
        <ButtonGroup aria-label="Tab bar filter">
            <Button variant={ isActive( course.active ) } onClick={ course.onClick }>{ course.label }</Button>
            <Button variant={ isActive( teacher.active ) } onClick={ teacher.onClick }>{ teacher.label }</Button>
        </ButtonGroup>
    </div>
);

export default TabBar;