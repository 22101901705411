import React, { useEffect, useState } from "react";
import { useForm } from "../../util/common";
import AuthForm from "../../components/AuthFormComponent/AuthForm";
import {
  useSelector,
  useDispatch,
  loginThunk,
  networkStatus,
  appAction,
} from "@mainnote/coremedalteacher";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { Container } from "react-bootstrap";
import Error from "../../components/NotificationComponent/Error";
import GoBackButton from "../../components/IconButtonComponent/GoBackButton";
import styles from "./css/AuthPage.module.css";
import { MessagePrompt } from "../../components";

const DeactivateAccountPage = () => {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);

  const isAuth = !!useSelector((state) => state.app.auth.jwt);
  const status = useSelector((state) => state.app.auth.status);
  const errors = useSelector((state) => state.app.auth.errors);
  const formType = useSelector((state) => state.app.auth.formType);

  const { form, formErrors, setFormErrors, submitButton, setFormField } =
    useForm({
      initForm: {},
      status,
      submitButtonText: formType,
    });

  const findFormErrors = () => {
    const { password } = form;
    const newErrors = {};

    // errors
    // TODO: validate password minmun length etc.
    if (!password || password === "")
      newErrors.password = "Password is required!";

    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // reset errors
    dispatch(appAction.resetErrors({ key: "auth" }));

    // get our new errors
    const newErrors = findFormErrors();
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setFormErrors(newErrors);
    } else {
      // No errors! Put any logic here for the form submission!
      setModalShow(true);
    }
  };
  const okHandler = () => {
    setModalShow(false);
    dispatch(loginThunk({ formType, userPassword: form.password }));
  };

  submitButton.handler = handleSubmit;

  const goBack = (e) => {
    e.preventDefault();
    history.goBack();
  };

  // fields
  const fields = {
    password: {
      controlId: "password",
      label: "Password",
      feedback: { text: formErrors.password, type: "invalid" },
      placeholder: "Enter Your Password",
      value: form.password || "",
      onChange: (e) => setFormField("password", e.target.value),
      isInvalid: !!formErrors.password,
    },
  };

  // effects
  // on page load
  useEffect(() => {
    if (isPageLoading) {
      // reset errors
      dispatch(appAction.resetErrors({ key: "auth" }));
      if (isAuth) {
      }
      setIsPageLoading(false);
    }
  }, [isPageLoading, dispatch, isAuth]);

  // on submit success
  useEffect(() => {
    if (!isPageLoading && status === networkStatus.succeeded) {
      dispatch({ type: "USER_LOGOUT" });

      if (history.location.state) {
        history.push(history.location.state.fromPath);
      } else {
        history.push("/");
      }
    }
  }, [status, history, isPageLoading, dispatch]);

  // on unmounting
  useEffect(() => {
    return () => {
      dispatch(appAction.resetErrors({ key: "auth" }));
    };
  }, [dispatch]);

  // rendering

  return (
    <Container className={styles.root}>
      <Error errors={errors} />
      <h1>{formType}</h1>
      <AuthForm submitButton={submitButton} fields={fields} />
      <GoBackButton onClick={goBack} />
      <MessagePrompt
        show={modalShow}
        heading="Account"
        bodyHeader="Remove this profile and account"
        body="Are you sure you want to remove your profile and account?"
        cancleHandler={() => setModalShow(false)}
        okHandler={okHandler}
      />
    </Container>
  );
};

export default withRouter(DeactivateAccountPage);
