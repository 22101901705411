import React, { useEffect, useState } from "react";
import { useForm, goToAuthPage } from "../../util/common";
import AuthForm from "../../components/AuthFormComponent/AuthForm";
import isEmail from "validator/lib/isEmail";
import { Container } from "react-bootstrap";
import styles from "./css/AuthPage.module.css";
import {
  useSelector,
  useDispatch,
  loginThunk,
  networkStatus,
  appAction,
} from "@mainnote/coremedalteacher";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import Error from "../../components/NotificationComponent/Error";
import GoBackButton from "../../components/IconButtonComponent/GoBackButton";
import { ToastContainer, Toast } from "react-bootstrap";

const AuthPage = () => {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const status = useSelector((state) => state.app.auth.status);
  const errors = useSelector((state) => state.app.auth.errors);
  const formType = useSelector((state) => state.app.auth.formType);
  const [toastShow, setToastShow] = useState(false);

  const { form, formErrors, setFormErrors, submitButton, setFormField } =
    useForm({
      initForm: {},
      status,
      submitButtonText: formType,
    });

  const findFormErrors = () => {
    const { email, password, repassword } = form;
    const newErrors = {};
    // email errors
    if (!email || email === "") newErrors.email = "Email is required!";
    else if (!isEmail(email)) newErrors.email = "Invalid email format!";

    // password
    if (formType !== "forgotPassword") {
      if (!password || password === "")
        newErrors.password = "password is required!";
      if (formType === "signup" && (!repassword || repassword !== password))
        newErrors.repassword = "Must be the same as above!";
    }
    return newErrors;
  };
  // handlers
  const goToForgotPasswordPage = goToAuthPage(
    history,
    dispatch,
    appAction,
    "/forgot-password",
    "/account",
    "forgotPassword"
  );

  const goToSignupPage = goToAuthPage(
    history,
    dispatch,
    appAction,
    "/signup",
    "/account",
    "signup"
  );

  const goBack = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // reset errors
    dispatch(appAction.resetErrors({ key: "auth" }));

    // get our new errors
    const newErrors = findFormErrors();
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setFormErrors(newErrors);
    } else {
      // No errors! Put any logic here for the form submission!
      dispatch(
        loginThunk({
          formType,
          userEmail: form.email,
          userPassword: form.password,
        })
      );
    }
  };
  submitButton.handler = handleSubmit;

  let fields = {
    email: {
      controlId: "emailAuthForm",
      label: "Email",
      text: "e.g. example@gmail.com",
      feedback: { text: formErrors.email, type: "invalid" },
      placeholder: "Enter your email",
      value: form.email || "",
      onChange: (e) => setFormField("email", e.target.value),
      isInvalid: !!formErrors.email,
    },
  };

  if (formType === "login" || formType === "signup") {
    fields.password = {
      controlId: "passwordAuthForm",
      label: "Enter Password",
      feedback: { text: formErrors.password, type: "invalid" },
      placeholder: "password",
      value: form.password || "",
      onChange: (e) => setFormField("password", e.target.value),
      isInvalid: !!formErrors.password,
    };
  }

  if (formType === "signup") {
    fields.repassword = {
      controlId: "repasswordAuthForm",
      label: "Enter Password again",
      feedback: { text: formErrors.repassword, type: "invalid" },
      placeholder: "password",
      value: form.repassword || "",
      onChange: (e) => setFormField("repassword", e.target.value),
      isInvalid: !!formErrors.repassword,
    };
  }

  if (formType === "login") {
    fields.forgotpassword = {
      text: "Forgot password?",
      onClick: goToForgotPasswordPage,
    };
    fields.signup = {
      text: "Signup",
      onClick: goToSignupPage,
    };
  }

  // effects
  // use effects
  useEffect(() => {
    if (isPageLoading) {
      // reset errors
      dispatch(appAction.resetErrors({ key: "auth" }));
      setIsPageLoading(false);
    }
  }, [isPageLoading, dispatch]);

  // after submit form
  useEffect(() => {
    if (!isPageLoading && status === networkStatus.succeeded) {
      // load user data if neccessary
      if (formType === "forgotPassword") {
        setToastShow(true);
      } else if (history.location.state) {
        history.push(history.location.state.fromPath);
      } else {
        history.push("/");
      }
    }
  }, [status, history, isPageLoading, formType]);

  // unmounting
  useEffect(() => {
    return () => {
      dispatch(appAction.resetErrors({ key: "auth" }));
    };
  }, [dispatch]);

  // rendering
  return (
    <Container className={styles.root}>
      <Error errors={errors} />
      <h1>{formType}</h1>
      <AuthForm submitButton={submitButton} fields={fields} />
      <ToastContainer className="p-3" position="top-center">
        <Toast
          bg="Info"
          delay={5000}
          autohide
          onClose={() => setToastShow(false)}
          show={toastShow}
        >
          <Toast.Body>
            {"Please find the reset password link in your email."}
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <GoBackButton onClick={goBack} />
    </Container>
  );
};

export default withRouter(AuthPage);
