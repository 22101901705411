import { CloseButton } from 'react-bootstrap';
import styles from './css/RemovableBubble.module.css';
import clsx from 'clsx';

const RemovableBubble = ( { text = '', clsoeButton = { onClick: () => { } }, showButton = true } ) => (
    <div className={ styles.root }>
        { text }
        { showButton &&
            <CloseButton
                className={ clsx( 'align-middle', styles.closeBtn ) }
                onClick={ clsoeButton.onClick } />
        }
    </div>
);

export default RemovableBubble;     