import { useState, useEffect } from "react";
import { Container, Row, Col, ListGroup } from "react-bootstrap";
import styles from "./css/AccountPage.module.css";
import MenuButton from "../../components/MenuComponent/MenuButton";
import { RiLoginCircleLine, RiLogoutCircleLine } from "react-icons/ri";
import { ImProfile } from "react-icons/im";
import { GiTeacher } from "react-icons/gi";
import { BsCalendarCheck } from "react-icons/bs";
import { GrFormAdd } from "react-icons/gr";
import { FiEye } from "react-icons/fi";
import { BiLockAlt } from "react-icons/bi";
import { AiOutlineMail } from "react-icons/ai";
import { RiAccountBoxLine } from "react-icons/ri";
import { FaUserClock } from "react-icons/fa";
import { MdSwitchAccount } from "react-icons/md";
import { MdOutlineDangerous } from "react-icons/md";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import {
  useSelector,
  useDispatch,
  onStudentThunk,
  onTeacherMeThunk,
  appAction,
  onTeacherSessionsThunk,
  selectValidTeacherSessionsState,
} from "@mainnote/coremedalteacher";
import { MessagePrompt } from "../../components";
import { goToPage, goToAuthPage } from "../../util/common";
import MenuListContainer from "../../components/MenuComponent/MenuListContainer";
import AvatarClickableItem from "../../components/AvatarComponent/AvatarClickableItem";
import SessionItem from "../../components/ScheduleComponent/SessionItem";
import ListMenu from "../../components/MenuComponent/ListMenu";
import MenuLink from "../../components/MenuComponent/MenuLink";
import Accordion from "../../components/ListGroupComponent/Accordion";

const AccountPage = () => {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const history = useHistory();
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();

  // states
  const isReload = useSelector((state) => state.app.isReload.account);
  const isAuth = !!useSelector((state) => state.app.auth.jwt);
  const user = useSelector((state) => state.app.user);
  const students = useSelector((state) => state.app.onStudents.students);
  const teachers = useSelector((state) => state.app.onTeacherMe.teacherMe);
  const teacherSessions = useSelector((state) =>
    selectValidTeacherSessionsState(state)
  );

  // handlers
  const goToLoginPage = goToAuthPage(
    history,
    dispatch,
    appAction,
    "/login",
    "/account",
    "login"
  );
  const goToChangePasswordPage = goToAuthPage(
    history,
    dispatch,
    appAction,
    "/change-password",
    "/account",
    "changePassword"
  );
  const goToSignupPage = goToAuthPage(
    history,
    dispatch,
    appAction,
    "/signup",
    "/account",
    "signup"
  );
  const goToDeactivateAccountPage = goToAuthPage(
    history,
    dispatch,
    appAction,
    "/deactivate-account",
    "/account",
    "deactivateAccount"
  );

  const goToLogoutPrompt = () => {
    setModalShow(true);
  };

  const logout = () => {
    dispatch({ type: "USER_LOGOUT" });
    history.push("/");
  };

  const goToStudentProfilePage = goToPage(history, "/student", "/account");
  const goToTeacherProfilePagePage = goToPage(
    history,
    "/teacher-profile",
    "/account"
  );
  const goToSetupSessionPage = goToPage(history, "/setup-session", "/account");

  // effects
  // on page load
  // fetch my account, students or teaching profile
  // todo: why do we need to reset errors?
  useEffect(() => {
    if (isPageLoading || isReload) {
      if (isAuth) {
        if (!(history?.location?.state?.fromPath === "/student")) {
          dispatch(appAction.resetErrors({ key: "onStudents" }));
          dispatch(onStudentThunk());
        }

        if (!(history?.location?.state?.fromPath === "/teacher-profile")) {
          dispatch(appAction.resetErrors({ key: "onTeacherMe" }));
          dispatch(onTeacherMeThunk());
        }

        if (!(history?.location?.state?.fromPath === "/setup-session")) {
          dispatch(appAction.resetErrors({ key: "onTeacherSessions" }));
          dispatch(onTeacherSessionsThunk());
        }
      }
      // reset reload flag
      dispatch(
        appAction.setStateByPath({ path: "isReload.account", value: false })
      );

      setIsPageLoading(false);
    }
  }, [isAuth, isPageLoading, isReload, dispatch, history]);

  // rendering
  return (
    <Container className="page-body">
      <Row>
        <Col className={styles.menuItems}>
          {isAuth && (
            <>
              {students?.length > 0 && (
                <ListGroup>
                  <MenuListContainer
                    header={"Student Profile"}
                    menu={
                      <ListMenu>
                        <MenuLink
                          icon={<GrFormAdd />}
                          onClick={goToStudentProfilePage}
                          label={"Add Student Profile"}
                        />
                      </ListMenu>
                    }
                  >
                    {students.map((entity) => (
                      <AvatarClickableItem
                        key={entity.id}
                        entity={entity}
                        onClick={goToStudentProfilePage}
                      />
                    ))}
                  </MenuListContainer>
                </ListGroup>
              )}

              {teachers?.length > 0 && (
                <ListGroup>
                  <MenuListContainer
                    header={"Teaching Profile"}
                    menu={
                      <ListMenu>
                        <MenuLink
                          icon={<FiEye />}
                          onClick={goToTeacherProfilePagePage}
                          label={"Add teaching profile"}
                        />
                      </ListMenu>
                    }
                  >
                    {teachers.map((entity) => (
                      <AvatarClickableItem
                        key={entity.id}
                        entity={entity}
                        onClick={goToTeacherProfilePagePage}
                      />
                    ))}
                  </MenuListContainer>
                </ListGroup>
              )}

              {teacherSessions && teacherSessions.length > 0 && (
                <ListGroup>
                  <MenuListContainer
                    header={"Session"}
                    menu={
                      <ListMenu>
                        <MenuLink
                          icon={<GrFormAdd />}
                          onClick={goToSetupSessionPage}
                          label={"Add session"}
                        />
                      </ListMenu>
                    }
                  >
                    {teacherSessions.map((entity) => (
                      <SessionItem
                        key={entity.id}
                        teacherSession={entity}
                        onClick={goToSetupSessionPage}
                      />
                    ))}
                  </MenuListContainer>
                </ListGroup>
              )}
              <ListGroup>
                <MenuButton
                  icon={<ImProfile />}
                  onClick={goToStudentProfilePage}
                >
                  Add student's profile
                </MenuButton>
              </ListGroup>
              <Accordion title="Account settings" iconComp={<MdSwitchAccount />}>
                <ListGroup>
                  <MenuButton icon={<AiOutlineMail />} disabled isLink={false}>
                    {user?.userName}
                  </MenuButton>
                </ListGroup>
                <ListGroup>
                  <MenuButton
                    icon={<BiLockAlt />}
                    onClick={goToChangePasswordPage}
                  >
                    Change Password
                  </MenuButton>
                </ListGroup>
                <ListGroup>
                  <MenuButton
                    icon={<MdOutlineDangerous />}
                    onClick={goToDeactivateAccountPage}
                  >
                    Delete profile and account
                  </MenuButton>
                </ListGroup>
              </Accordion>
              <Accordion title="Teaching centre" iconComp={<FaUserClock />}>
                <ListGroup>
                  <MenuButton
                    icon={<GiTeacher />}
                    onClick={goToTeacherProfilePagePage}
                  >
                    Add teaching profile
                  </MenuButton>
                </ListGroup>
                <ListGroup>
                  <MenuButton
                    icon={<BsCalendarCheck />}
                    disabled={teachers?.length === 0 ? true : false}
                    onClick={goToSetupSessionPage}
                  >
                    Setup session
                  </MenuButton>
                </ListGroup>
              </Accordion>
            </>
          )}
          <ListGroup>
            <MenuButton
              icon={isAuth ? <RiLogoutCircleLine /> : <RiLoginCircleLine />}
              onClick={isAuth ? goToLogoutPrompt : goToLoginPage}
            >
              {isAuth ? "Logout" : "Login"}
            </MenuButton>
          </ListGroup>
          {!isAuth && (
            <ListGroup>
              <MenuButton icon={<RiAccountBoxLine />} onClick={goToSignupPage}>
                Sign up
              </MenuButton>
            </ListGroup>
          )}
        </Col>
      </Row>
      <MessagePrompt
        show={modalShow}
        heading="Logout"
        bodyHeader="Are you sure you want to logout?"
        body="You will be logged out of your account and will be redirected to the home page."
        cancleHandler={() => setModalShow(false)}
        okHandler={logout}
      />
    </Container>
  );
};

export default withRouter(AccountPage);
