import { Form } from "react-bootstrap";
import FormControl from "./FormControl";

const FormSelect = ({ field }) => (
  <FormControl
    controlId={field.controlId}
    label={field.label}
    text={field.text}
    feedback={{ text: field.feedback.text, type: field.feedback.type }}
  >
    <Form.Select
      aria-label={field.label}
      onChange={field.onChange}
      value={field.value || ""}
    >
      <option value={null}>{field.placeholder}</option>
      <>
        {field.selections.map((item, index) => (
          <option key={index} value={item}>
            {item}
          </option>
        ))}
      </>
    </Form.Select>
  </FormControl>
);

export default FormSelect;
