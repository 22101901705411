import { useEffect } from "react";
import { connectSocket, useSelector, setBaseUrl } from "@mainnote/coremedalteacher";

setBaseUrl(
  process.env.REACT_APP_BASE_URL,
  process.env.REACT_APP_BASE_SOCKET_URL
);

const AppSocket = ({ children }) => {
  const jwtToken = useSelector((state) => state.app.auth.jwt);

  // effects
  useEffect(() => {
    const socket = connectSocket(jwtToken);
    return () => {
      if (socket) socket.disconnect();
    };
  }, [jwtToken]);

  return <div className="App">{children}</div>;
};

export default AppSocket;
