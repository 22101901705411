import { Container, Row, Col, ListGroup } from 'react-bootstrap';
import styles from './css/MenuButton.module.css';
import { MdKeyboardArrowRight } from "react-icons/md";

const MenuButton = ( { icon, children, isLink = true, disabled = false, onClick = () => { } } ) => (
    <ListGroup.Item className={ styles.root } disabled={ disabled } onClick={ onClick }>
        <Container>
            <Row>
                { icon &&
                    <Col xs="auto text-center">
                        { icon }
                    </Col>
                }
                <Col>
                    { children }
                </Col>

                { isLink &&
                    <Col xs="auto text-center">
                        <MdKeyboardArrowRight />
                    </Col>
                }
            </Row>
        </Container>
    </ListGroup.Item>
);
export default MenuButton;