import smartTruncate from "smart-truncate";
import styles from "./css/Event.module.css";
import AvatarTextItem from "../AvatarComponent/AvatarTextItem";
import { DescriptionText } from "../../elements";

const Event = ({ session }) => {
  const { maxAttendees, attendees, teacher, requests, request } = session;

  return (
    <div className={styles.root}>
      {teacher && (
        <div className={styles.tag}>
          <AvatarTextItem
            entity={teacher}
            showField="picture"
            text={smartTruncate(teacher.fullName, 20)}
          />
        </div>
      )}
      {session.type === "student" && request?.student && (
        <div className={styles.tag}>
          <AvatarTextItem
            entity={request?.student}
            showField="picture"
            text={smartTruncate(request?.student.fullName, 20)}
          />
        </div>
      )}
      {session.type === "teacher" && (
        <div className={styles.tag}>
          <DescriptionText>
            {attendees} / {maxAttendees} students joined
          </DescriptionText>
          <div className={styles.studentsWrap}>
            {requests?.length > 0 &&
              requests.map((request) => (
                <div className={styles.student} key={request.id}>
                  <AvatarTextItem
                    entity={request.student}
                    showField="picture"
                    text={smartTruncate(request.student.fullName, 20)}
                  />
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Event;
