import FormControl from "./FormControl";
import AvatarClickableItem from "../AvatarComponent/AvatarClickableItem";
import UploadFile from "./UploadFile";

const FormUploadFile = ( { field } ) => (
    <FormControl
        controlId={ field.controlId }
        label={ field.label }
        text={ field.text }
        feedback={ { text: field.feedback.text, type: field.feedback.type } }>
        { field.value &&
            <AvatarClickableItem
                entity={ field.value }
            />
        }
        <UploadFile
            files={ field.files }
            onDrop={ field.onDrop }
            dropzoneProps={ {
                accept: 'image/*',
                maxFiles: 1,
            } } />
    </FormControl>
);

export default FormUploadFile;