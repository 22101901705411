import styles from './css/GoBackButton.module.scss';
import IconButton from './IconButton';
import { IoArrowBackCircleOutline } from "react-icons/io5";

const GoBackButton = ( { onClick = () => { } } ) => (
    <div className={ styles.root }>
        <IconButton onClick={ onClick } btnStyle={ { borderRadius: '60px' } }>
            <IoArrowBackCircleOutline className={ styles.icon } />
        </IconButton>
    </div>
);

export default GoBackButton;