import styles from "./css/DayLargeDisplay.module.scss";
import clsx from "clsx";

const DayLargeDisplay = ({ monthNum, monthStr, dayNum, yearNum }) => (
  <div className={styles.dateWrapper}>
    <div className={styles.flexVertical}>
      <div className={clsx(styles.head, "background-color-" + monthNum)}>
        {monthStr}
      </div>
      <div
        className={clsx(styles.body, "align-items-center", "align-self-center")}
      >
        {dayNum}
      </div>
      <div className={styles.foot}>{yearNum}</div>
    </div>
  </div>
);

export default DayLargeDisplay;
