import { Row, Col, Container, Badge } from "react-bootstrap";
import styles from "./css/BottomNavigationBar.module.scss";
import IconButton from "../IconButtonComponent/IconButton";
import { GiGraduateCap } from "react-icons/gi";
import { IoCalendarOutline } from "react-icons/io5";
import { TbBellRinging } from "react-icons/tb";
import { VscAccount } from "react-icons/vsc";
import clsx from "clsx";

const BottomNavigationBar = ({
  className = "",
  home = {},
  requests = {},
  schedule = {},
  account = {},
  notification = {},
}) => (
  <Container fluid className={clsx(styles.root, className)}>
    <Row>
      <Col className="text-center">
        <IconButton onClick={home.onClick}>
          <GiGraduateCap
            className={clsx(styles.icon, home.active && styles.active)}
          />
          <p className={clsx(styles.label, home.active && styles.active)}>
            Home
          </p>
        </IconButton>
      </Col>
      <Col className="text-center">
        <IconButton onClick={requests.onClick}>
          <TbBellRinging
            className={clsx(styles.icon, requests.active && styles.active)}
          />
          <p className={clsx(styles.label, requests.active && styles.active)}>
            Requests
          </p>
          {!!(notification.studentRequest) && (
            <Badge className={styles.badge} bg="danger">
              {notification.studentRequest}
            </Badge>
          )}
        </IconButton>
      </Col>
      <Col className="text-center">
        <IconButton onClick={schedule.onClick}>
          <IoCalendarOutline
            className={clsx(styles.icon, schedule.active && styles.active)}
          />
          <p className={clsx(styles.label, schedule.active && styles.active)}>
            Schedule
          </p>
        </IconButton>
      </Col>
      <Col className="text-center">
        <IconButton onClick={account.onClick}>
          <VscAccount
            className={clsx(styles.icon, account.active && styles.active)}
          />
          <p className={clsx(styles.label, account.active && styles.active)}>
            Account
          </p>
        </IconButton>
      </Col>
    </Row>
  </Container>
);

export default BottomNavigationBar;
