import { ListGroup } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingSpinner from "../LoadingProgressComponent/LoadingSpinner";

const ScollLoadList = ({ children, data, next, hasMore }) => {
  return (
    <ListGroup variant="flush">
      <InfiniteScroll
        dataLength={data.length}
        next={next}
        hasMore={hasMore}
        loader={<LoadingSpinner />}
        endMessage={
          <p style={{ textAlign: "center", color: "grey" }}>Yay! That's all.</p>
        }
      >
        {children}
      </InfiniteScroll>
    </ListGroup>
  );
};

export default ScollLoadList;
