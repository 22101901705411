import { Figure, Col } from "react-bootstrap";
import styles from "./css/AvatarClickableItem.module.css";
import { getImageThumbnail } from "@mainnote/coremedalteacher";
import Avatar from "../AvatarComponent/Avatar";
import clsx from "clsx";

export const getEntityThumbnail = (entity) => {
  const thumbnail = getImageThumbnail(entity?.picture);
  return thumbnail ? thumbnail.url : "";
};

const AvatarClickableItem = ({
  entity,
  onClick = () => {},
  selected = false,
}) => {
  const thumbnailUrl = getEntityThumbnail(entity);

  return (
    <Col xs="2">
      <Figure
        className={clsx(styles.bg, selected && styles.selected)}
        onClick={() => onClick(entity?.id)}
      >
        <Avatar url={thumbnailUrl} alt={entity?.fullName} selected={selected} />
        <Figure.Caption className={styles.caption}>
          {entity?.fullName}
        </Figure.Caption>
      </Figure>
    </Col>
  );
};

export default AvatarClickableItem;
