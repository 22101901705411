import { Button } from "react-bootstrap";
import styles from "./css/MenuLink.module.scss";

const MenuLink = ( { icon, label, onClick } ) => (
    <Button
        className={ styles.root }
        variant="light"
        onClick={ onClick }>
        { icon }{ label }
    </Button>
);

export default MenuLink;