import React, { useState, useEffect } from "react";
import styles from "./css/CoursePage.module.css";
import { withRouter } from "react-router";
import { Container, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import GoBackButton from "../../components/IconButtonComponent/GoBackButton";
import Image from "react-bootstrap/Image";
import {
  useDispatch,
  coursesAction,
  getCourseByIdThunk,
  useSelector,
  selectSearchCourseById,
  getMediasImage,
  networkStatus,
  findCourseTeachers,
} from "@mainnote/coremedalteacher";
import TeacherListGroup from "../../components/ListGroupComponent/TeacherListGroup";
import LoadingSpinner from "../../components/LoadingProgressComponent/LoadingSpinner";
import Error from "../../components/NotificationComponent/Error";
import IconFiveLevels from "../../components/IconButtonComponent/IconFiveLevels";
import { CheckIcon, SectionTitle } from "../../elements";

const CoursePage = () => {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  let { courseId } = useParams();
  if (typeof courseId === "string") courseId = parseInt(courseId);

  const goBack = (e) => {
    e.preventDefault();
    history.goBack();
  };

  // state
  const status = useSelector((state) => state.courses.loaded.status);
  const errors = useSelector((state) => state.courses.loaded.errors);
  const course = useSelector((state) =>
    selectSearchCourseById(state, courseId)
  );

  // handlers
  const showTeacherDetail = (teacher) => {
    history.push("/teacher/" + teacher.id);
  };

  // effects
  // on page loading
  useEffect(() => {
    if (isPageLoading) {
      // reset errors
      dispatch(coursesAction.resetErrors({ key: "loaded" }));
      if (!course) dispatch(getCourseByIdThunk(`filters[id][$eq]=${courseId}`));
      setIsPageLoading(false);
    }
  }, [isPageLoading, dispatch, courseId, course]);

  // rendering
  let content;
  if (course) {
    const thumbnail = getMediasImage(course.medias, "medium");
    const thumbnailUrl = thumbnail ? thumbnail.url : "";
    content = (
      <>
        <Row>
          <Image src={thumbnailUrl} fluid className={styles.bannerImage} />
        </Row>
        <Row>
          <h2>
            {course.courseName}
            {course.isCertified && <CheckIcon />}
            <IconFiveLevels level={course.level} />
          </h2>
          <p className={styles.introduction}>{course.courseIntroduction}</p>
          <p>{course.courseDetailInformation}</p>
          <SectionTitle>Choose a teacher</SectionTitle>
          <TeacherListGroup
            teachers={findCourseTeachers(course.teacher_and_courses)}
            onClick={showTeacherDetail}
          />
        </Row>
      </>
    );
  } else {
    content = (
      <>
        {status === networkStatus.loading ? (
          <LoadingSpinner />
        ) : (
          <Row>
            <h2>course not found</h2>
          </Row>
        )}
      </>
    );
  }

  // render
  return (
    <Container className="page-body">
      <Error errors={errors} />
      {content}
      <GoBackButton onClick={goBack} />
    </Container>
  );
};

export default withRouter(CoursePage);
