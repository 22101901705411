import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import GoBackButton from "../../components/IconButtonComponent/GoBackButton";
import { withRouter } from "react-router";
import {
  useSelector,
  useDispatch,
  networkStatus,
  teachersAction,
  selectPublicTeacherSessionById,
  getPublicTeacherSessionThunk,
  sendRequestForSessionThunk,
  selectTeacherSessionsErrors,
  selectSearchTeacherById,
  findMyRequestOfStudentAndSession,
  appAction,
  onStudentThunk,
  myRequestsThunk,
} from "@mainnote/coremedalteacher";
import Error from "../../components/NotificationComponent/Error";
import { MessagePrompt, ViewPrompt, StudentView } from "../../components";
import MenuListContainer from "../../components/MenuComponent/MenuListContainer";
import SessionItem from "../../components/ScheduleComponent/SessionItem";
import AvatarClickableItem from "../../components/AvatarComponent/AvatarClickableItem";
import { goToPage } from "../../util/common";
import FormItems from "../../components/FormControlComponent/FormItems";
import smartTruncate from "smart-truncate";
import SessionDisplayItem from "../../components/ScheduleComponent/SessionDisplayItem";
import TeacherEvaluation from "../../components/TeacherComponent/TeacherEvaluation";

const TeacherSessionPage = () => {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const history = useHistory();
  let { teacherId } = useParams();
  if (typeof teacherId === "string") teacherId = parseInt(teacherId);

  const dispatch = useDispatch();
  const goBack = (e) => {
    e.preventDefault();
    history.goBack();
  };
  const [modalShow, setModalShow] = useState(false);
  const [chosenTeacherSession, setChosenTeacherSession] = useState(null);
  const [chosenStudentId, setChosenStudentId] = useState(null);
  const [chosenTeacherAndCourse, setChosenTeacherAndCourse] = useState(null);
  const [chosenRequest, setChosenRequest] = useState(null);
  const [toastShow, setToastShow] = useState(false);
  const [messagePromptDisplay, setMessagePromptDisplay] = useState(false);
  const [isViewOnly, setIsViewOnly] = useState(false);
  const [studentModalShow, setStudentModalShow] = useState(null);

  // state
  const status = useSelector(
    (state) => state.teachers.sendRequestForSession.status
  );
  const status_students = useSelector((state) => state.app.onStudents.status);
  const errors = useSelector((state) => selectTeacherSessionsErrors(state));
  const teacher = useSelector((state) =>
    selectSearchTeacherById(state, teacherId)
  );
  const myRequests = useSelector(
    (state) => state.teachers.onMyRequests.myRequests
  );
  const publicTeacherSessions = useSelector((state) =>
    selectPublicTeacherSessionById(state, teacherId)
  );
  const students = useSelector((state) => state.app.onStudents.students);

  // handlers

  const handleChosenStudent = (studentId) => {
    dispatch(teachersAction.resetErrors({ key: "sendRequestForSession" }));
    if (studentId === chosenStudentId) {
      setChosenStudentId(null);
    } else {
      setChosenStudentId(studentId);
    }
  };

  const promptForConfirmation = (teacherSession) => {
    dispatch(teachersAction.resetErrors({ key: "sendRequestForSession" }));
    setChosenTeacherSession(teacherSession);
    setChosenRequest(null);
    setChosenTeacherAndCourse(null);
    setMessagePromptDisplay(false);
    setIsViewOnly(false);
    setModalShow(true);
  };
  const promptForDisplay = (teacherSession) => {
    dispatch(teachersAction.resetErrors({ key: "sendRequestForSession" }));
    setChosenTeacherSession(teacherSession);
    // from teacher session to find the requrest, if there is one teacher_and_course
    const requests = findMyRequestOfStudentAndSession(
      myRequests,
      chosenStudentId || students[0].id,
      teacherSession.id
    );

    // set request status
    if (requests.length > 0) setChosenRequest(requests[0]);

    // set as chosenTeacherAndCourse
    if (
      !teacherSession?.teacher_and_course &&
      requests.length > 0 &&
      requests[0].teacher_and_course
    ) {
      const teacherAndCourse = teacher.teacher_and_courses.find(
        (entity) => entity.id === requests[0].teacher_and_course.id
      );

      setChosenTeacherAndCourse(teacherAndCourse);
    } else {
      setChosenTeacherAndCourse(null);
    }
    setMessagePromptDisplay(true);
    setIsViewOnly(true);
    setModalShow(true);
  };

  const confirmedSendingRequest = (event) => {
    event.preventDefault();
    if (chosenTeacherSession?.teacher_and_course || chosenTeacherAndCourse) {
      dispatch(
        sendRequestForSessionThunk({
          teacherId,
          teacherSessionId: chosenTeacherSession.id,
          studentId: chosenStudentId || students[0].id,
          teacherAndCourseId: chosenTeacherAndCourse?.id,
        })
      );
      setModalShow(false);
    } else {
      setToastShow(true);
    }
  };
  const viewStudent = (student) => setStudentModalShow(student);

  // effects
  // on page load
  useEffect(() => {
    if (isPageLoading) {
      // reset errors
      dispatch(teachersAction.resetErrors({ key: "getPublicTeacherSession" }));
      dispatch(teachersAction.resetErrors({ key: "sendRequestForSession" }));
      dispatch(teachersAction.resetErrors({ key: "onMyRequests" }));
      dispatch(getPublicTeacherSessionThunk(teacherId));
      dispatch(myRequestsThunk());
      if (!students || students.length === 0) {
        dispatch(appAction.resetErrors({ key: "onStudents" }));
        dispatch(onStudentThunk());
      }
      setIsPageLoading(false);
    }
  }, [isPageLoading, dispatch, teacherId, students]);

  // load students if not available after page load
  useEffect(() => {
    if (
      !isPageLoading &&
      (status_students === networkStatus.succeeded ||
        status_students === networkStatus.reloaded)
    ) {
      if (!students || students.length === 0) {
        const goToStudentProfilePage = goToPage(
          history,
          "/student",
          `/teacher-session/${teacherId}`
        );
        goToStudentProfilePage();
      }
    }
  }, [isPageLoading, status_students, students, history, teacherId]);

  // on submit success
  useEffect(() => {
    if (!isPageLoading && status === networkStatus.succeeded) {
      setChosenTeacherSession(null);
      dispatch(
        teachersAction.addError({
          key: "sendRequestForSession",
          error: {
            message: "Request sent successfully!",
            variant: "success",
          },
        })
      );
    }
  }, [isPageLoading, status, dispatch]);

  // on unmounting
  useEffect(() => {
    return () => {
      dispatch(teachersAction.resetErrors({ key: "getPublicTeacherSession" }));
      dispatch(teachersAction.resetErrors({ key: "sendRequestForSession" }));
      dispatch(teachersAction.resetErrors({ key: "onMyRequests" }));
    };
  }, [dispatch]);

  // rendering
  return (
    <Container className="page-body">
      <span>{teacher.fullName ? teacher.fullName : ""}</span>
      <TeacherEvaluation
        title={teacher?.title}
        isCertified={teacher.isCertified}
        rate={teacher.rate}
      />
      <Error errors={errors} />

      <h4>Choose a student profile</h4>
      <FormItems>
        {students?.length > 0 &&
          students.map((entity) => (
            <AvatarClickableItem
              key={entity.id}
              selected={
                chosenStudentId === entity.id ||
                (!chosenStudentId && students[0].id === entity.id)
              }
              entity={entity}
              onClick={handleChosenStudent}
            />
          ))}
      </FormItems>

      <h4>Choose a session</h4>
      {students?.length > 0 &&
        publicTeacherSessions?.length > 0 &&
        publicTeacherSessions.map((courseSessions, index) => (
          <MenuListContainer
            key={index}
            header={
              (smartTruncate(
                courseSessions.teacher_and_course?.course?.courseName,
                42
              ) || "All course") +
              (courseSessions.teacher_and_course?.hourlyRate
                ? `  ( $${courseSessions.teacher_and_course?.hourlyRate} per hour )`
                : " ( Free ) ")
            }
          >
            {courseSessions.sessions.map((teacherSession) => {
              const selected =
                findMyRequestOfStudentAndSession(
                  myRequests,
                  chosenStudentId || students[0].id,
                  teacherSession.id
                ).length > 0;
              return (
                <SessionItem
                  key={teacherSession.id}
                  teacherSession={teacherSession}
                  selected={selected}
                  onClick={selected ? promptForDisplay : promptForConfirmation}
                />
              );
            })}
          </MenuListContainer>
        ))}
      <MessagePrompt
        show={modalShow}
        heading={
          messagePromptDisplay
            ? "Request Detail" +
              (!!chosenRequest ? " (" + chosenRequest.requestStatus + ")" : "")
            : "Confirm request"
        }
        content={
          <SessionDisplayItem
            teacherSession={chosenTeacherSession}
            teacher={teacher}
            chosenTeacherAndCourse={chosenTeacherAndCourse}
            setChosenTeacherAndCourse={setChosenTeacherAndCourse}
            isViewOnly={isViewOnly}
            viewStudent={viewStudent}
          />
        }
        bodyHeader="Are you sure you want to take this session?"
        body="Your request will send to the teacher. The teacher will review your request and decide to accept or not. Stay tuned!"
        cancleHandler={() => setModalShow(false)}
        okHandler={confirmedSendingRequest}
        toastProps={{
          onClose: () => setToastShow(false),
          show: toastShow,
          body: "Choose a course, please.",
        }}
        isDisplay={messagePromptDisplay}
      />
      <ViewPrompt
        show={!!studentModalShow}
        heading="Student"
        cancleHandler={() => setStudentModalShow(null)}
      >
        <StudentView student={studentModalShow} />
      </ViewPrompt>
      <GoBackButton onClick={goBack} />
    </Container>
  );
};

export default withRouter(TeacherSessionPage);
