import { ImBooks } from "react-icons/im";
import IconButton from "../IconButtonComponent/IconButton";

const EmptyList = ({
  goToLoginPage,
  isAuth,
  textWithAuth,
  textWithOutAuth,
}) => {
  return (
    <div className="text-center">
      <IconButton onClick={isAuth ? () => {} : goToLoginPage}>
        <ImBooks className="m-auto" size="150" />
        <p className="text-muted">{isAuth ? textWithAuth : textWithOutAuth}</p>
      </IconButton>
    </div>
  );
};

export default EmptyList;
