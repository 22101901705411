import { Container, Row, Col } from "react-bootstrap";
import styles from "./css/MenuListContainer.module.css";
import clsx from "clsx";

const MenuListContainer = ( { header = '', menu, children } ) => (
    <Container className={ clsx( 'shadow', 'mb-3', styles.root ) }>
        <Row>
            <Col xs={ 8 }>
                <h6 className="text-muted mt-1">{ header }</h6>
            </Col>
            <Col xs={ 4 }>
                { menu }
            </Col>
        </Row>
        <Row>
            { children }
        </Row>
    </Container>
);

export default MenuListContainer;