import { Form } from "react-bootstrap";
import FormControl from './FormControl';

const FormInput = ( { field, inputProps = {} } ) => (
    <FormControl
        controlId={ field.controlId }
        label={ field.label }
        text={ field.text }
        feedback={ { text: field.feedback.text, type: field.feedback.type } }>
        <Form.Control
            type="text"
            placeholder={ field.placeholder }
            value={ field.value }
            onChange={ field.onChange }
            isInvalid={ field.isInvalid }
            { ...inputProps }
        />
    </FormControl>
);

export default FormInput;