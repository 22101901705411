import React, { useCallback } from "react";
import { withRouter } from "react-router";
import { Switch, Route, useHistory } from "react-router-dom";
import styles from "./css/MainNavigator.module.css";
import { useSelector, useDispatch, appAction } from "@mainnote/coremedalteacher";
import BottomNavigationBar from "../../components/BottomNavigationBarComponent/BottomNavigationBar";
import Home from "../HomePage/HomePage";
import SchedulePage from "../SchedulePage/SchedulePage";
import AccountPage from "../AccountPage/AccountPage";
import CoursePage from "../CoursePage/CoursePage";
import TeacherPage from "../TeacherPage/TeacherPage";
import AuthPage from "../AuthPage/AuthPage";
import ChangePasswordPage from "../AuthPage/ChangePasswordPage";
import ResetPasswordPage from "../AuthPage/ResetPasswordPage";
import DeactivateAccountPage from "../AuthPage/DeactivateAccountPage";
import StudentProfilePage from "../AccountPage/StudentProfilePage";
import TeacherProfilePage from "../AccountPage/TeacherProfilePage";
import TeacherSessionPage from "../TeacherPage/TeacherSessionPage";
import SetupSessionPage from "../AccountPage/SetupSessionPage";
import RequestPage from "../RequestPage/RequestPage";
import RequestsPage from "../RequestPage/RequestsPage";

const updateNav = (dispatch, page) => dispatch(appAction.updateNav(page));
const useLink = (dispatch, history, page) =>
  useCallback(() => {
    updateNav(dispatch, page);
    let path;
    switch (page) {
      case "requests":
        path = "/requests";
        break;
      case "schedule":
        path = "/schedule";
        break;
      case "account":
        path = "/account";
        break;
      default:
        path = "/";
    }
    history.push(path);
  }, [dispatch, history, page]);

const MainNavigator = () => {
  const history = useHistory();
  const nav = useSelector((state) => state.app.nav);
  const notification = useSelector((state) => state.app.notification);
  const dispatch = useDispatch();

  const goToHome = useLink(dispatch, history, "home");
  const goToRequests = useLink(dispatch, history, "requests");
  const goToSchedule = useLink(dispatch, history, "schedule");
  const goToAccount = useLink(dispatch, history, "account");

  return (
    <>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/requests">
          <RequestsPage />
        </Route>
        <Route path="/schedule">
          <SchedulePage />
        </Route>
        <Route path="/account">
          <AccountPage />
        </Route>
        <Route path="/teacher/:teacherId">
          <TeacherPage />
        </Route>
        <Route path="/course/:courseId">
          <CoursePage />
        </Route>
        <Route path="/login">
          <AuthPage />
        </Route>
        <Route path="/signup">
          <AuthPage />
        </Route>
        <Route path="/forgot-password">
          <AuthPage />
        </Route>
        <Route path="/reset-password">
          <ResetPasswordPage />
        </Route>
        <Route path="/change-password">
          <ChangePasswordPage />
        </Route>
        <Route path="/deactivate-account">
          <DeactivateAccountPage />
        </Route>
        <Route path="/student/:studentId?">
          <StudentProfilePage />
        </Route>
        <Route path="/teacher-profile/:teacherId?">
          <TeacherProfilePage />
        </Route>
        <Route path="/teacher-session/:teacherId">
          <TeacherSessionPage />
        </Route>
        <Route path="/setup-session/:teacherSessionId?">
          <SetupSessionPage />
        </Route>
        <Route path="/request/:requestId?">
          <RequestPage />
        </Route>
      </Switch>

      {/* bottom navigation bar */}
      <BottomNavigationBar
        className={styles.bottom}
        home={{ onClick: goToHome, active: nav.home }}
        requests={{ onClick: goToRequests, active: nav.requests }}
        schedule={{ onClick: goToSchedule, active: nav.schedule }}
        account={{ onClick: goToAccount, active: nav.account }}
        notification={notification}
      />
    </>
  );
};

export default withRouter(MainNavigator);
