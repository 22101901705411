import { Card, Row, Col } from "react-bootstrap";
import styles from "./css/CourseItem.module.scss";
import { getMediasImage, findCourseTeachers } from "@mainnote/coremedalteacher";
import smartTruncate from "smart-truncate";
import clsx from "clsx";
import IconFiveLevels from "../IconButtonComponent/IconFiveLevels";
import AvatarDisplayItem from "../AvatarComponent/AvatarDisplayItem";
import { DescriptionText } from "../../elements";
import { ExternalImage, CheckIcon } from "../../elements";

const CourseItem = ({ course = {}, selected, onClick = () => {} }) => {
  const thumbnail = getMediasImage(course.medias);
  const thumbnailUrl = thumbnail ? thumbnail.url : "";

  return (
    <div
      className={clsx(styles.root, selected && styles.selected)}
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
    >
      <Card className={"border-0"}>
        <Row className={styles.row}>
          <Col xs={5}>
            <ExternalImage src={thumbnailUrl} />
          </Col>
          <Col xs={7} className={styles.col_body}>
            <Card.Body className={styles.card_body}>
              <Card.Title>
                {smartTruncate(course.courseName, 50) || ""}
                {course.isCertified && <CheckIcon />}
                <IconFiveLevels level={course.level} />
              </Card.Title>
              <DescriptionText>
                {smartTruncate(course?.courseIntroduction, 120) || ""}
              </DescriptionText>
              <Row className={styles.cardBottom}>
                {findCourseTeachers(course.teacher_and_courses).map(
                  (entity) => (
                    <AvatarDisplayItem
                      className={styles.avatar}
                      key={entity.id}
                      size={18}
                      entity={entity}
                    />
                  )
                )}
              </Row>
            </Card.Body>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default CourseItem;
