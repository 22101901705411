import { Col } from "react-bootstrap";
import { getImageThumbnail, getMediasImage } from "@mainnote/coremedalteacher";
import Avatar from "./Avatar";

export const getEntityThumbnail = (entity, showField) => {
  const thumbnail =
    showField === "picture"
      ? getImageThumbnail(entity?.picture)
      : getMediasImage(entity?.medias);
  return thumbnail ? thumbnail.url : "";
};

const AvatarDisplayItem = ({
  className = "",
  entity,
  size,
  showField = "picture",
  avatarStyle = {},
  iconStyle = {},
}) => {
  const thumbnailUrl = getEntityThumbnail(entity, showField);
  const _avatarStyle = size ? { width: `${size}px`, height: `${size}px` } : {};
  const _iconStyle = size ? { width: `${size}px`, height: `${size}px` } : {};

  return (
    <Col xs="2" className={className}>
      <Avatar
        url={thumbnailUrl}
        alt={entity?.fullName}
        avatarStyle={{ ..._avatarStyle, ...avatarStyle }}
        iconStyle={{ ..._iconStyle, ...iconStyle }}
        showBorder={false}
      />
    </Col>
  );
};

export default AvatarDisplayItem;
