import { Modal } from "react-bootstrap";

const ViewPrompt = ({
  show,
  heading = "heading",
  children,
  cancleHandler = () => {},
}) => (
  <Modal show={show} onHide={cancleHandler} size="lg" centered>
    <Modal.Header closeButton>
      <Modal.Title>{heading}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{children}</Modal.Body>
  </Modal>
);

export default ViewPrompt;
