import FormControl from "./FormControl";
import { Container, Button } from "react-bootstrap";
import { AiOutlineSearch } from "react-icons/ai";

const FormSearchItem = ({ children, field }) => (
  <FormControl
    controlId={field.controlId}
    label={field.label}
    text={field.text}
    feedback={{ text: field.feedback.text, type: field.feedback.type }}
  >
    <Container className="mb-2 p-2">{children}</Container>
    <Button variant="outline-secondary" onClick={field.onClick}>
      <AiOutlineSearch />
      {field.placeholder}
    </Button>
  </FormControl>
);

export default FormSearchItem;
