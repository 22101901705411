// import styles from './AuthFormComponent.module.css';
import { Form, Button } from "react-bootstrap";
import FormInput from "../FormControlComponent/FormInput";
import SubmitButton from "../FormControlComponent/SubmitButton";

const AuthForm = ({ fields = {}, submitButton = {} }) => {
  return (
    <Form>
      {/* Email */}
      {fields.hasOwnProperty("email") && (
        <FormInput field={fields.email} inputProps={{ type: "email" }} />
      )}

      {/* Current Password for changing password */}
      {fields.hasOwnProperty("currentPassword") && (
        <FormInput
          field={fields.currentPassword}
          inputProps={{ type: "password" }}
        />
      )}

      {/* Password */}
      {fields.hasOwnProperty("password") && (
        <FormInput field={fields.password} inputProps={{ type: "password" }} />
      )}

      {/* Re-enter password */}
      {fields.hasOwnProperty("repassword") && (
        <FormInput
          field={fields.repassword}
          inputProps={{ type: "password" }}
        />
      )}

      {/* submit button */}
      {submitButton && <SubmitButton submitButton={submitButton} />}

      {/* Forgot password */}
      {fields.hasOwnProperty("forgotpassword") && (
        <Button variant="link" onClick={fields.forgotpassword.onClick}>
          {fields.forgotpassword.text}
        </Button>
      )}
      {/* Signup */}
      {fields.hasOwnProperty("signup") && (
        <div>
          <Button onClick={fields.signup.onClick}  variant="outline-primary">
            {fields.signup.text}
          </Button>
        </div>
      )}
    </Form>
  );
};

export default AuthForm;
