import { Modal, Button } from 'react-bootstrap';
import { ToastContainer, Toast } from 'react-bootstrap';

const MessagePrompt = ( {
    show,
    heading = 'heading',
    content = <></>,
    bodyHeader = 'body header',
    body = 'body',
    cancleHandler = () => { },
    okHandler = () => { },
    toastProps = { show: false },
    isDisplay = false,
} ) => (
    <Modal
        show={ show }
        onHide={ cancleHandler }
        size="lg"
        centered
    >
        <Modal.Header closeButton>
            <Modal.Title>
                { heading }
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            { content }
            { !isDisplay &&
                <>
                    <h4>{ bodyHeader }</h4>
                    <p>{ body }</p>
                </>
            }
            <ToastContainer className="p-3" position="bottom-center">
                <Toast
                    { ...toastProps }
                    bg="warning"
                    delay={ 2000 }
                    autohide>
                    <Toast.Body>{ toastProps.body }</Toast.Body>
                </Toast>
            </ToastContainer>
        </Modal.Body>
        { !isDisplay &&
            <Modal.Footer>
                <Button variant="secondary" onClick={ cancleHandler }>
                    Cancel
                </Button>
                <Button variant="primary" onClick={ okHandler }>
                    Confirm
                </Button>
            </Modal.Footer>
        }
    </Modal>
);

export default MessagePrompt;