import { Container } from "react-bootstrap";
import Error from "../NotificationComponent/Error";
import NotFound from "../NotificationComponent/NotFound";
import GoBackButton from "../IconButtonComponent/GoBackButton";
import MessagePrompt from "../NotificationComponent/MessagePrompt";

const EditFormWrap = ({
  title,
  errors,
  goBack,
  messagePromptProps,
  description,
  entityId,
  entity,
  notFoundMessage = "",
  children,
}) => (
  <Container className="page-body">
    <h1>{title}</h1>
    {description && description.length > 0 && (
      <small className="text-muted">{description}</small>
    )}
    <Error errors={errors} />
    {entityId && !entity ? <NotFound itemName={notFoundMessage} /> : children}
    <GoBackButton onClick={goBack} />

    <MessagePrompt {...messagePromptProps} />
  </Container>
);

export default EditFormWrap;
