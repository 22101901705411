import { Accordion, Container, Row, Col } from "react-bootstrap";
import styles from './css/Accordion.module.css';

const AccordionComp = ( { children, title = '', iconComp = <></> } ) => {
  return (
    <Accordion className={ styles.root }>
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <Container>
            <Row>
              <Col className="col-auto text-center">
                { iconComp }
              </Col>
              <Col>
                { title }
              </Col>
            </Row>
          </Container>
        </Accordion.Header>
        <Accordion.Body>
          { children }
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
};

export default AccordionComp;
