import { memo } from "react";
import CourseListItem from "./CourseListItem";
import NotFound from "../NotificationComponent/NotFound";
import ScollLoadList from "./ScollLoadList";
import { isEqual } from "lodash";

const CourseListGroup = ({
  courses = [],
  onClick = () => {},
  next = () => {},
  hasMore = false,
  showNotFound = true,
  selectedItemId,
}) => {
  if (courses.length === 0) {
    return showNotFound ? <NotFound itemName="courses" /> : <></>;
  }

  const items = courses.map((course) => (
    <CourseListItem
      key={course.id}
      selected={selectedItemId && course.teacherAndCourse.id === selectedItemId}
      course={course}
      onClick={() => onClick(course)}
    />
  ));

  return (
    <ScollLoadList data={courses} next={next} hasMore={hasMore}>
      {items}
    </ScollLoadList>
  );
};

const isDataEqual = (prevProps, nextProps) => {
  return (
    isEqual(prevProps.courses, nextProps.courses) &&
    isEqual(prevProps.selectedItemId, nextProps.selectedItemId) &&
    isEqual(prevProps.showNotFound, nextProps.showNotFound)
  );
};

export default memo(CourseListGroup, isDataEqual);
