import { memo } from "react";
import { isEqual } from "lodash";
import { ListGroup } from "react-bootstrap";
import TeacherItem from "./TeacherItem";

const TeacherListItem = (props) => {
  return (
    <ListGroup.Item className="d-flex justify-content-center">
      <TeacherItem {...props} />
    </ListGroup.Item>
  );
};

const isDataEqual = (prevProps, nextProps) => {
  return isEqual(prevProps.teacher, nextProps.teacher);
};

export default memo(TeacherListItem, isDataEqual);
