import { Container, Form, Row, Col } from "react-bootstrap";
import FormInput from "../FormControlComponent/FormInput";
import FormSelect from "../FormControlComponent/FormSelect";
import SubmitButton from "../FormControlComponent/SubmitButton";
import DeleteButton from "../FormControlComponent/DeleteButton";
import FormUploadFile from "../FormControlComponent/FormUploadFile";
import FormSearchItem from "../FormControlComponent/FormSearchItem";
import TeacherCourseItem from "../ListGroupComponent/TeacherCourseItem";

const TeacherProfileForm = ({
  fields = {},
  entityId,
  entity,
  submitButton = {},
  deleteButton = {},
}) => {
  return (
    <Container>
      <Form>
        <Row>
          <Col md>
            {/* First Name */}
            {fields.hasOwnProperty("firstName") && (
              <FormInput field={fields.firstName} />
            )}
          </Col>
          <Col md>
            {/* Middle Name */}
            {fields.hasOwnProperty("middleName") && (
              <FormInput field={fields.middleName} />
            )}
          </Col>
          <Col md>
            {/* Last Name */}
            {fields.hasOwnProperty("lastName") && (
              <FormInput field={fields.lastName} />
            )}
          </Col>
        </Row>
        {/* choose teacher title */}
        {fields.hasOwnProperty("title") && <FormSelect field={fields.title} />}
        {/* upload teaching profile image */}
        {fields.hasOwnProperty("profilePicture") && (
          <FormUploadFile field={fields.profilePicture} />
        )}
        {/* introduction */}
        {fields.hasOwnProperty("introduction") && (
          <FormInput
            field={fields.introduction}
            inputProps={{
              type: "text",
              as: "textarea",
              rows: 2,
            }}
          />
        )}
        {/* biography */}
        {fields.hasOwnProperty("biography") && (
          <FormInput
            field={fields.biography}
            inputProps={{
              type: "text",
              as: "textarea",
              rows: 3,
            }}
          />
        )}
        {/* course chosen */}
        {fields.hasOwnProperty("teacher_and_courses") && (
          <FormSearchItem field={fields.teacher_and_courses}>
            {fields.teacher_and_courses.value.map((teacher_and_course) => (
              <TeacherCourseItem
                key={teacher_and_course.course.id}
                teacher_and_course={teacher_and_course}
                {...fields.teacher_and_courses.itemProps}
              />
            ))}
          </FormSearchItem>
        )}
        {/* submit button */}
        {submitButton && <SubmitButton submitButton={submitButton} />}
        {/* delete button */}
        {entityId && entity && <DeleteButton deleteButton={deleteButton} />}
      </Form>
    </Container>
  );
};
export default TeacherProfileForm;
