import React, { useState, useEffect } from "react";
import styles from "./css/TeacherPage.module.scss";
import { withRouter } from "react-router";
import { Container, Row, Button } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import GoBackButton from "../../components/IconButtonComponent/GoBackButton";
import Image from "react-bootstrap/Image";
import {
  useDispatch,
  teachersAction,
  getTeacherByIdThunk,
  getImageThumbnail,
  useSelector,
  selectSearchTeacherById,
  networkStatus,
  findTeacherCourses,
} from "@mainnote/coremedalteacher";
import CourseListGroup from "../../components/ListGroupComponent/CourseListGroup";
import LoadingSpinner from "../../components/LoadingProgressComponent/LoadingSpinner";
import Error from "../../components/NotificationComponent/Error";
import TeacherEvaluation from "../../components/TeacherComponent/TeacherEvaluation";
import { SectionTitle } from "../../elements";

const TeacherPage = () => {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  let { teacherId } = useParams();
  if (typeof teacherId === "string") teacherId = parseInt(teacherId);
  const goBack = (e) => {
    e.preventDefault();
    history.goBack();
  };

  // state
  const isAuth = !!useSelector((state) => state.app.auth.jwt);
  const status = useSelector((state) => state.teachers.loaded.status);
  const teacher = useSelector((state) =>
    selectSearchTeacherById(state, teacherId)
  );
  const errors = useSelector((state) => state.teachers.loaded.errors);

  // handlers
  const showCourseDetail = (course) => {
    history.push("/course/" + course.id);
  };
  const goToTeacherSession = (e) => {
    e.preventDefault();
    if (isAuth) {
      history.push(`/teacher-session/${teacherId}`);
    } else {
      history.push("/login", { fromPath: `/teacher-session/${teacherId}` });
    }
  };

  // effects
  // on page loading
  useEffect(() => {
    if (isPageLoading) {
      // reset errors
      dispatch(teachersAction.resetErrors({ key: "loaded" }));
      if (!teacher)
        dispatch(getTeacherByIdThunk(`filters[id][$eq]=${teacherId}`));
      setIsPageLoading(false);
    }
  }, [isPageLoading, dispatch, teacherId, teacher]);

  // rendering
  let content;
  if (teacher) {
    const thumbnail = getImageThumbnail(teacher?.picture, "medium");
    const thumbnailUrl = thumbnail ? thumbnail.url : "";

    content = (
      <>
        <Row className="position-relative">
          <Image src={thumbnailUrl} fluid className={styles.bannerImage} />
          <Button
            className={styles.btnBookApp}
            onClick={goToTeacherSession}
            variant="success"
          >
            Book Session
          </Button>
        </Row>
        <Row>
          <h2>
            {teacher.fullName ? teacher.fullName : ""}
            <TeacherEvaluation
              title={teacher?.title}
              isCertified={teacher.isCertified}
              rate={teacher.rate}
            />
          </h2>
          <p className={styles.introduction}>{teacher.introduction}</p>
          <p>{teacher.biography}</p>
          <SectionTitle>Courses</SectionTitle>
          <CourseListGroup
            courses={findTeacherCourses(teacher?.teacher_and_courses)}
            onClick={showCourseDetail}
          />
        </Row>
      </>
    );
  } else {
    content = (
      <>
        {status === networkStatus.loading ? (
          <LoadingSpinner />
        ) : (
          <Row>
            <h2>teacher not found</h2>
          </Row>
        )}
      </>
    );
  }

  return (
    <Container className="page-body">
      <Error errors={errors} />
      {content}
      <GoBackButton onClick={goBack} />
    </Container>
  );
};

export default withRouter(TeacherPage);
