import React, { useEffect, useState } from "react";
import { useForm, useQuery } from "../../util/common";
import AuthForm from "../../components/AuthFormComponent/AuthForm";
import {
  useSelector,
  useDispatch,
  loginThunk,
  networkStatus,
  appAction,
} from "@mainnote/coremedalteacher";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { Container } from "react-bootstrap";
import Error from "../../components/NotificationComponent/Error";
import styles from "./css/AuthPage.module.css";

const ResetPasswordPage = () => {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();
  const code = query.get("code");

  // state
  const isAuth = !!useSelector((state) => state.app.auth.jwt);
  const status = useSelector((state) => state.app.auth.status);
  const errors = useSelector((state) => state.app.auth.errors);
  const formType = "resetPassword";

  const { form, formErrors, setFormErrors, submitButton, setFormField } =
    useForm({
      initForm: {},
      status,
      submitButtonText: formType,
    });

  const findFormErrors = () => {
    const { password, repassword } = form;
    const newErrors = {};

    // errors
    // TODO: validate password minmun length etc.
    if (!password || password === "")
      newErrors.password = "Password is required!";
    if (password !== repassword)
      newErrors.repassword = "Passwords do not match";

    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // reset errors
    dispatch(appAction.resetErrors({ key: "auth" }));

    // get our new errors
    const newErrors = findFormErrors();
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setFormErrors(newErrors);
    } else {
      // No errors! Put any logic here for the form submission!
      dispatch(
        loginThunk({
          formType,
          userPassword: form.password,
          repassword: form.repassword,
          code,
        })
      );
    }
  };
  submitButton.handler = handleSubmit;

  // fields
  const fields = {
    password: {
      controlId: "password",
      label: "New Password",
      feedback: { text: formErrors.password, type: "invalid" },
      placeholder: "Enter New Password",
      value: form.password || "",
      onChange: (e) => setFormField("password", e.target.value),
      isInvalid: !!formErrors.password,
    },
    repassword: {
      controlId: "passwordConfirmation",
      label: "New Password Confirmation",
      feedback: { text: formErrors.repassword, type: "invalid" },
      placeholder: "Enter New Password Confirmation",
      value: form.repassword || "",
      onChange: (e) => setFormField("repassword", e.target.value),
      isInvalid: !!formErrors.repassword,
    },
  };

  // effects
  // on page load
  useEffect(() => {
    if (isPageLoading) {
      // reset errors
      dispatch(appAction.resetErrors({ key: "auth" }));
      if (isAuth) {
      }
      setIsPageLoading(false);
    }
  }, [isPageLoading, dispatch, isAuth]);

  // on submit success
  useEffect(() => {
    if (!isPageLoading && status === networkStatus.succeeded) {
      if (history.location.state) {
        history.push(history.location.state.fromPath);
      } else {
        history.push("/");
      }
    }
  }, [status, history, isPageLoading]);

  // on unmounting
  useEffect(() => {
    return () => {
      dispatch(appAction.resetErrors({ key: "auth" }));
    };
  }, [dispatch]);

  // rendering

  return (
    <Container className={styles.root}>
      <Error errors={errors} />
      <h1>{formType}</h1>
      <AuthForm submitButton={submitButton} fields={fields} />
    </Container>
  );
};

export default withRouter(ResetPasswordPage);
