import styles from "./css/DayEvents.module.css";
import { DayLargeDisplay, TwoRowsItem } from "../../elements";
import { toLocalDateTime } from "@mainnote/coremedalteacher";
import { ListGroup } from "react-bootstrap";
import Event from "./Event";
import RowCourseName from "./RowCourseName";

const DayEvents = ({ events, onClick }) => {
  const eventDate = events[0];
  const eventSessions = events[1];

  return (
    <ListGroup.Item className={styles.root}>
      <DayLargeDisplay
        monthNum={toLocalDateTime(eventDate, "MM")}
        monthStr={toLocalDateTime(eventDate, "MMM")}
        dayNum={toLocalDateTime(eventDate, "DD")}
        yearNum={toLocalDateTime(eventDate, "YYYY")}
      />
      <ListGroup variant="flush" className={styles.times}>
        {eventSessions.map((session, i) => (
          <div
            key={i}
            className={session.type === "teacher" ? styles.teacher : null}
          >
            <TwoRowsItem
              title={<RowCourseName session={session} />}
              detail={<Event session={session} />}
              onClick={(e) => {
                e.preventDefault();
                onClick(session);
              }}
            />
          </div>
        ))}
      </ListGroup>
    </ListGroup.Item>
  );
};

export default DayEvents;
