import { GiChiliPepper } from "react-icons/gi";
import styles from './css/IconLevel.module.css';
import clsx from 'clsx';

const IconLevel = ( { type } ) => {
    let content;

    switch ( type ) {
        case 'full':
            content = <GiChiliPepper className={ clsx( styles.icon, styles.full ) } />;
            break;
        default:
            content = <GiChiliPepper className={ clsx( styles.icon, styles.empty) } />;
    }

    return content;
};

export default IconLevel;