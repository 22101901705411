import { Container, Row } from 'react-bootstrap';

const FormItems = ( { children } ) => (
    <Container>
        <Row>
            { children }
        </Row>
    </Container>
);

export default FormItems;