import { Form } from 'react-bootstrap';
import clsx from 'clsx';
import styles from './css/NumberInput.module.css';

const NumberInput = ( {
    value = '',
    digits = 1,
    unit = '',
    onChange : _onChange = () => { },
} ) => {
    const onChange = ( event ) => {
        event.preventDefault();
        _onChange( event.target.value );
    };

    return (
        <div>
            <Form.Control
                className={ clsx( "text-end", styles.fieldValue ) }
                style={ { width: `${ digits + 0.6 }rem` } }
                type="number"
                placeholder={ '0'.repeat( digits ) }
                onChange={ onChange }
                value={ value }
            />
            <span>{ unit }</span>
        </div>
    );
};

export default NumberInput;