import { IoStar, IoStarHalf, IoStarOutline } from "react-icons/io5";
import styles from './css/IconStar.module.css';

const IconStar = ( { type } ) => {
    let content;

    switch ( type ) {
        case 'full':
            content = <IoStar className={ styles.icon } />;
            break;
        case 'half':
            content = <IoStarHalf className={ styles.icon } />;
            break;
        default:
            content = <IoStarOutline className={ styles.icon } />;
    }

    return content;
};

export default IconStar;