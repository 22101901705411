import { Form, Button } from "react-bootstrap";
import DateTimeInput from "./DateTimeInput";
import { FormGroup, RemovableBubble, NumberInput } from "../../elements";
import SubmitButton from "../FormControlComponent/SubmitButton";
import DeleteButton from "../FormControlComponent/DeleteButton";
import AvatarClickableItem from "../AvatarComponent/AvatarClickableItem";
import FormItems from "../FormControlComponent/FormItems";
import CourseListGroup from "../ListGroupComponent/CourseListGroup";
import {
  findTeacherCourses,
  toLocalDateTime,
} from "@mainnote/coremedalteacher";

const FieldFormGroup = ({ field, children }) => (
  <FormGroup
    controlId={field.controlId}
    label={field.label}
    text={field.helpText}
    feedback={field.feedback}
  >
    {children}
  </FormGroup>
);

const SetupSessionForm = ({
  fields = {},
  entityId,
  entity,
  submitButton = {},
  deleteButton = {},
}) => {
  const { startTime, duration, maxAttendees, teachers } = fields;

  return (
    <Form>
      <FieldFormGroup field={startTime}>
        <div className="mb-2">
          {startTime.availableTimes?.length > 0 &&
            startTime.availableTimes?.map((availableTime, ind) => (
              <RemovableBubble
                key={ind}
                clsoeButton={{
                  onClick: (e) => {
                    e.preventDefault();
                    startTime.handleRemove(availableTime);
                  },
                }}
                text={toLocalDateTime(availableTime)}
              />
            ))}
        </div>
        <DateTimeInput value={startTime.value} onChange={startTime.onChange} />
        <Button className="mx-2" variant="light" onClick={startTime.handleAdd}>
          Add
        </Button>
      </FieldFormGroup>
      <FieldFormGroup field={duration}>
        <NumberInput
          digits={3}
          unit={"minutes"}
          value={duration.value}
          onChange={duration.onChange}
        />
      </FieldFormGroup>
      <FieldFormGroup field={maxAttendees}>
        <NumberInput
          digits={6}
          unit={"students"}
          value={maxAttendees.value}
          onChange={maxAttendees.onChange}
        />
      </FieldFormGroup>
      <FieldFormGroup field={teachers}>
        <FormItems>
          {teachers?.items?.length > 0 &&
            teachers?.items?.map((entity) => (
              <AvatarClickableItem
                key={entity.id}
                selected={teachers?.value === entity.id}
                entity={entity}
                onClick={teachers.onClick}
              />
            ))}
        </FormItems>
        <FormItems>
          {teachers?.value && (
            <CourseListGroup
              courses={findTeacherCourses(
                (teachers?.items.find(
                  (entity) => teachers?.value === entity.id
                )).teacher_and_courses
              )}
              selectedItemId={teachers.teacherAndCourseId}
              showNotFound={false}
              onClick={teachers.courseOnClick}
            />
          )}
        </FormItems>
      </FieldFormGroup>

      {/* submit button */}
      {submitButton && <SubmitButton submitButton={submitButton} />}
      {/* delete button */}
      {entityId && entity && <DeleteButton deleteButton={deleteButton} />}
    </Form>
  );
};

export default SetupSessionForm;
