import AvatarDisplayItem from "../AvatarComponent/AvatarDisplayItem";

const StudentView = ({ student }) => {
  if (!student) return <></>;

  return (
    <>
      <h2>{student.fullName ? student.fullName : ""}</h2>
      <AvatarDisplayItem size={80} entity={student} />
      <p
        style={{
          color: "grey",
        }}
      >
        {student.introduction}
      </p>
      <p>{student.biography}</p>
    </>
  );
};

export default StudentView;
