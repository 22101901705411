import React, { StrictMode } from "react";
import { Provider, getStore } from "@mainnote/coremedalteacher";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";

const [store, persistor] = getStore(storage);

const StoreProvider = ({ children }) => {
  return (
    <StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {children}
        </PersistGate>
      </Provider>
    </StrictMode>
  );
};

export default StoreProvider;
