import styles from './css/IconButton.module.css';
import clsx from 'clsx';

const IconButton = ( { btnStyle = {}, onClick = () => { }, children } ) => (
    <div className={ clsx( 'text-center', styles.btn ) }
        style={ btnStyle }
        onClick={ onClick }
    >
        { children }
    </div>
);

export default IconButton;

