import React, { useEffect, useState } from "react";
import { useForm, isInt } from "../../util/common";
import SetupSessionForm from "../../components/ScheduleComponent/SetupSessionForm";
import {
  useSelector,
  useDispatch,
  networkStatus,
  networkAction,
  appAction,
  selectTeacherSessionById,
  onTeacherSessionsThunk,
  sortAndFilterAvailableTimes,
} from "@mainnote/coremedalteacher";
import { withRouter } from "react-router";
import { useHistory, useParams } from "react-router-dom";
import EditFormWrap from "../../components/FormControlComponent/EditFormWrap";

const getFormValue = (entity) => ({
  teacherSessionId: entity.id,
  startTime: new Date(),
  duration: entity.duration,
  maxAttendees: entity.maxAttendees,
  teacherId: entity.teacher?.id,
  teacherAndCourseId: entity.teacher_and_course?.id,
  availableTimes: sortAndFilterAvailableTimes(
    entity.timeslots?.map((timeslot) => new Date(timeslot.availableTime))
  ),
});

const SetupSessionPage = () => {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const history = useHistory();
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();

  // state
  const isAuth = !!useSelector((state) => state.app.auth.jwt);
  const status = useSelector((state) => state.app.onTeacherSessions.status);
  const errors = useSelector((state) => state.app.onTeacherSessions.errors);
  const teachers = useSelector((state) => state.app.onTeacherMe.teacherMe);

  let { teacherSessionId } = useParams();
  if (typeof teacherSessionId === "string")
    teacherSessionId = parseInt(teacherSessionId);

  const teacherSession = useSelector((state) =>
    selectTeacherSessionById(state, teacherSessionId)
  );
  let initForm = {
    startTime: new Date(),
    duration: 60,
    maxAttendees: 1,
    teacherId: null,
    availableTimes: [],
  };
  if (isPageLoading && teacherSession) {
    initForm = getFormValue(teacherSession);
  }

  const {
    form,
    setForm,
    formErrors,
    setFormErrors,
    submitButton,
    deleteButton,
    setFormField,
  } = useForm({ initForm, status });

  const findFormErrors = () => {
    // const { startTime, duration, maxAttendees } = form;
    const newErrors = {};
    // errors
    if (!form.teacherId)
      newErrors.teachers = "Please select a teaching profile.";
    if (!form.availableTimes || form.availableTimes.length === 0)
      newErrors.startTime = "Please add a start time.";
    if (!isInt(form.duration) || form.duration <= 0)
      newErrors.duration = "Please input a valid duration.";
    if (!isInt(form.maxAttendees) || form.maxAttendees <= 0)
      newErrors.maxAttendees = "Please input a valid attendee number.";

    return newErrors;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // reset errors
    dispatch(appAction.resetErrors({ key: "onTeacherSessions" }));

    // get our new errors
    const newErrors = findFormErrors();
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setFormErrors(newErrors);
    } else {
      // No errors! Put any logic here for the form submission!
      dispatch(
        onTeacherSessionsThunk({
          action: networkAction.post,
          id: form.teacherSessionId,
          availableTimes: form.availableTimes || [],
          duration: parseInt(form.duration),
          maxAttendees: parseInt(form.maxAttendees),
          teacher: {
            id: form.teacherId,
          },
          teacher_and_course: {
            id: form.teacherAndCourseId,
          },
        })
      );
    }
  };
  submitButton.handler = handleSubmit;

  const handleDelete = (e) => {
    e.preventDefault();
    setModalShow(true);
  };
  deleteButton.handler = handleDelete;

  const removeTeacherSession = () => {
    if (teacherSession) {
      dispatch(
        onTeacherSessionsThunk({
          action: networkAction.delete,
          id: teacherSessionId,
        })
      );
    }
    setModalShow(false);
  };
  const goBack = (e) => {
    e.preventDefault();
    history.goBack();
  };
  const handleAdd = (e) => {
    e.preventDefault();
    if (
      form.startTime &&
      form.startTime > new Date() &&
      !form.availableTimes.includes(form.startTime)
    ) {
      const value = sortAndFilterAvailableTimes([
        ...form.availableTimes,
        form.startTime,
      ]);
      setFormField("availableTimes", value);
    } else {
      setFormErrors({ startTime: "Please add a valid start time." });
    }
  };
  const handleRemove = (availableTime) => {
    const value = sortAndFilterAvailableTimes(
      form.availableTimes.filter((time) => time !== availableTime)
    );
    setFormField("availableTimes", value);
  };

  // fields
  // todo: validate onChange of form fields
  const fields = {
    startTime: {
      controlId: "formStartDateTime",
      label: "Start date and time",
      helpText: "When will you be available?",
      feedback: { text: formErrors.startTime, type: "invalid" },
      value: form.startTime || new Date(),
      onChange: (value) => setFormField("startTime", value),
      availableTimes: form.availableTimes,
      handleAdd,
      handleRemove,
    },
    duration: {
      controlId: "formDuration",
      label: "Duration",
      helpText: "How many minutes do you want to set for one session?",
      feedback: { text: formErrors.duration, type: "invalid" },
      value: form.duration,
      onChange: (value) => setFormField("duration", value),
    },
    maxAttendees: {
      controlId: "formMaxAttendees",
      label: "Maximum Attendees",
      helpText: "How many students do you want in this session?",
      feedback: { text: formErrors.maxAttendees, type: "invalid" },
      value: form.maxAttendees,
      onChange: (value) => setFormField("maxAttendees", value),
    },
    teachers: {
      controlId: "formTeachers",
      label: "Teaching Profiles",
      helpText:
        "Choose one of your teaching profiles then you may specify a course.",
      feedback: { text: formErrors.teachers, type: "invalid" },
      value: form.teacherId,
      teacherAndCourseId: form.teacherAndCourseId,
      items: teachers,
      onClick: (teacherId) => {
        if (teacherId === form.teacherId) {
          setFormField("teacherAndCourseId", null);
          setFormField("teacherId", null);
        } else {
          setFormField("teacherId", teacherId);
        }
      },
      courseOnClick: (course) => {
        if (course?.teacherAndCourse?.id === form.teacherAndCourseId) {
          setFormField("teacherAndCourseId", null);
        } else {
          setFormField("teacherAndCourseId", course.teacherAndCourse.id);
        }
      },
    },
  };
  // effects
  // on page load
  useEffect(() => {
    if (isPageLoading) {
      // reset errors
      dispatch(appAction.resetErrors({ key: "onTeacherSessions" }));
      if (isAuth) {
        dispatch(onTeacherSessionsThunk());
      }
      setIsPageLoading(false);
    }

    if (!isPageLoading && status === networkStatus.reloaded && teacherSession) {
      setForm(getFormValue(teacherSession));
      dispatch(appAction.resetErrors({ key: "onTeacherSessions" }));
    }
  }, [isPageLoading, dispatch, isAuth, status, setForm, teacherSession]);

  // on submit success
  useEffect(() => {
    if (!isPageLoading && status === networkStatus.succeeded) {
      if (history?.location?.state?.fromPath) {
        history.push(history.location.state.fromPath, {
          fromPath: "/setup-session",
        });
      } else {
        history.push("/");
      }
    }
  }, [status, history, isPageLoading]);

  // on unmounting
  useEffect(() => {
    return () => {
      dispatch(appAction.resetErrors({ key: "onTeacherSessions" }));
    };
  }, [dispatch]);

  // rendering

  return (
    <EditFormWrap
      title={"Setup session"}
      errors={errors}
      goBack={goBack}
      messagePromptProps={{
        show: modalShow,
        heading: "Remove this session",
        bodyHeader: "Are you sure you want to remove this session?",
        body: "This session will be deleted.",
        cancleHandler: () => setModalShow(false),
        okHandler: removeTeacherSession,
      }}
      description={"* Public information"}
      entityId={teacherSessionId}
      entity={teacherSession}
      notFoundMessage={"Session not found!"}
    >
      <SetupSessionForm
        fields={fields}
        entityId={teacherSessionId}
        entity={teacherSession}
        submitButton={submitButton}
        deleteButton={deleteButton}
      />
    </EditFormWrap>
  );
};

export default withRouter(SetupSessionPage);
