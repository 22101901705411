import StoreProvider from "./StoreProvider";
import MainNavigator from "../pages/Navigator/MainNavigator";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.scss";
import AppSocket from "./AppSocket";

function App() {
  return (
    <StoreProvider>
      <AppSocket>
        <Router>
          <MainNavigator />
        </Router>
      </AppSocket>
    </StoreProvider>
  );
}

export default App;
