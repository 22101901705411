import { useState, useCallback, useMemo } from "react";
import { networkStatus } from "@mainnote/coremedalteacher";
import { useLocation } from "react-router-dom";

export const isDev = (() => {
  if (
    !process.env.NODE_ENV ||
    process.env.NODE_ENV === "development" ||
    process.env.NODE_ENV === "test"
  ) {
    // dev code
    return true;
  } else {
    // production code
    return false;
  }
})();

export const useFiles = ({ cacheItem }) => {
  const [files, setFiles] = useState([]);
  // upload files
  const onDrop = useCallback(
    (acceptedFiles) => {
      cacheItem.files = acceptedFiles;
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    [cacheItem]
  );

  return { files, onDrop };
};

export const useForm = ({
  initForm = {},
  status = networkStatus.idle,
  submitButtonText,
  deleteButtonText,
}) => {
  const [form, setForm] = useState(initForm);
  const [formErrors, setFormErrors] = useState({});

  const submitButton = {
    text: submitButtonText || "Submit",
    isLoading: status === networkStatus.loading,
  };

  const deleteButton = {
    text: deleteButtonText || "Delete",
    isLoading: status === networkStatus.loading,
  };

  const setFormField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    // Check and see if errors exist, and remove them from the error object:
    if (!!formErrors[field])
      setFormErrors({
        ...formErrors,
        [field]: null,
      });
  };

  return {
    form,
    setForm,
    formErrors,
    setFormErrors,
    submitButton,
    deleteButton,
    setFormField,
  };
};

export const goToPage =
  (history, url, fromPath, data = {}) =>
  (dataId) => {
    if (
      dataId &&
      (typeof dataId === "string" ||
        typeof dataId === "number" ||
        (typeof dataId === "object" && !!dataId.id))
    ) {
      if (typeof dataId === "object") dataId = dataId.id;
      url = `${url}/${dataId}`;
    }
    history.push(url, { fromPath, ...data });
  };

export const goToAuthPage =
  (history, dispatch, appAction, url, fromPath, formType) => (e) => {
    e.preventDefault();
    dispatch(
      appAction.setStateByPath({ path: "auth.formType", value: formType })
    );
    history.push(url, { fromPath });
  };

export function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export function isInt(str) {
  if (typeof str != "string" && typeof str != "number") return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseInt(str))
  ); // ...and ensure strings of whitespace fail
}
