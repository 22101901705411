import React, { useEffect, useState, useCallback } from "react";
import { Container } from "react-bootstrap";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import SearchBar from "../../components/SearchBarComponent/SearchBar";
import TabBar from "../../components/TabBarComponent/TabBar";
import {
  appAction,
  useSelector,
  useDispatch,
  searchAction,
  searchCoursesThunk,
  selectSearchCourses,
  coursesAction,
  searchTeachersThunk,
  selectSearchTeachers,
  teachersAction,
  selectHasMore,
  networkStatus,
} from "@mainnote/coremedalteacher";
import CourseListGroup from "../../components/ListGroupComponent/CourseListGroup";
import TeacherListGroup from "../../components/ListGroupComponent/TeacherListGroup";
import Error from "../../components/NotificationComponent/Error";
import { isEmpty } from "lodash";

const updateTabBar = (dispatch, tab) =>
  dispatch(searchAction.updateTabBar(tab));
const useTabBar = (dispatch, tab) => () => {
  updateTabBar(dispatch, tab);
};

const HomePage = () => {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isSearchCancel, setIsSearchCancel] = useState(false);
  const history = useHistory();
  // const { t } = useTranslation();

  // states
  const isReload = useSelector((state) => state.app.isReload.home);
  const tabBar = useSelector((state) => state.search.tabBar);
  const coursesStatus = useSelector((state) => state.courses.search.status);
  const teachersStatus = useSelector((state) => state.teachers.search.status);
  const coursesErrors = useSelector((state) => state.courses.search.errors);
  const teachersErrors = useSelector((state) => state.teachers.search.errors);
  const errors = tabBar.course.active ? coursesErrors : teachersErrors;
  const searchText = useSelector((state) => state.search.searchText);

  const searchCourses = useSelector((state) => selectSearchCourses(state));
  const searchTeachers = useSelector((state) => selectSearchTeachers(state));
  const hasMoreCourses = useSelector((state) =>
    selectHasMore(state.courses.search.pagination)
  );
  const hasMoreTeachers = useSelector((state) =>
    selectHasMore(state.teachers.search.pagination)
  );

  // handlers
  const dispatch = useDispatch();
  const chooseCourse = useTabBar(dispatch, "course");
  const chooseTeacher = useTabBar(dispatch, "teacher");

  const showCourseDetail = (course) => {
    history.push("/course/" + course.id);
  };
  const showTeacherDetail = (teacher) => {
    history.push("/teacher/" + teacher.id);
  };

  const handleSearchChange = (searchText) => {
    dispatch(searchAction.updateSearchText(searchText));
  };

  const searchCoursesWithQuery = useCallback(() => {
    const searchQuery =
      searchText && searchText.length > 0 ? `_q=${searchText}` : null;
    dispatch(searchCoursesThunk(searchQuery));
  }, [dispatch, searchText]);
  const searchTeachersWithQuery = useCallback(() => {
    const searchQuery =
      searchText && searchText.length > 0 ? `_q=${searchText}` : null;
    dispatch(searchTeachersThunk(searchQuery));
  }, [dispatch, searchText]);

  const handleSearchClick = useCallback(() => {
    dispatch(coursesAction.resetSearch());
    dispatch(teachersAction.resetSearch());
    searchCoursesWithQuery();
    searchTeachersWithQuery();
  }, [dispatch, searchCoursesWithQuery, searchTeachersWithQuery]);

  const handleSearchCancel = () => {
    handleSearchClear();
    setIsSearchCancel(true);
  };

  const handleSearchClear = () => {
    dispatch(searchAction.updateSearchText(""));
  };

  // effects
  useEffect(() => {
    if (isSearchCancel) {
      handleSearchClick();
      setIsSearchCancel(false);
    }
  }, [isSearchCancel, handleSearchClick]);

  // on page loading
  useEffect(() => {
    if (isPageLoading || isReload) {
      // reset errors
      dispatch(coursesAction.resetErrors({ key: "search" }));
      dispatch(coursesAction.resetErrors({ key: "loaded" }));

      if (
        isPageLoading ||
        (isEmpty(searchCourses) && isEmpty(searchTeachers))
      ) {
        dispatch(searchAction.updateSearchText(""));
        dispatch(coursesAction.resetSearch());
        dispatch(teachersAction.resetSearch());
        dispatch(searchCoursesThunk());
        dispatch(searchTeachersThunk());
      }

      // reset reload flag
      dispatch(
        appAction.setStateByPath({ path: "isReload.home", value: false })
      );
      setIsPageLoading(false);
    }
  }, [
    isPageLoading,
    dispatch,
    searchCourses,
    searchTeachers,
    searchText,
    isReload,
  ]);

  // rendering
  return (
    <Container className="page-body">
      <Error errors={errors} />
      <SearchBar
        placeholder="Find a course or teacher"
        value={searchText}
        handleSearchChange={handleSearchChange}
        handleSearchClick={handleSearchClick}
        handleOnClickHome={handleSearchCancel}
      />
      <TabBar
        course={{
          ...tabBar.course,
          onClick: chooseCourse,
        }}
        teacher={{
          ...tabBar.teacher,
          onClick: chooseTeacher,
        }}
      />
      {tabBar.course.active ? (
        <CourseListGroup
          courses={searchCourses}
          onClick={showCourseDetail}
          next={() => {
            if (coursesStatus !== networkStatus.loading)
              searchCoursesWithQuery();
          }}
          hasMore={hasMoreCourses}
          showNotFound={coursesStatus === networkStatus.succeeded}
        />
      ) : (
        <TeacherListGroup
          teachers={searchTeachers}
          onClick={showTeacherDetail}
          next={() => {
            if (teachersStatus !== networkStatus.loading)
              searchTeachersWithQuery();
          }}
          hasMore={hasMoreTeachers}
          showNotFound={teachersStatus === networkStatus.succeeded}
        />
      )}
    </Container>
  );
};

export default withRouter(HomePage);
