import { Card } from "react-bootstrap";
import { AiOutlineUser } from "react-icons/ai";
import styles from "./css/ExternalImage.module.css";

const ExternalImage = ({ src }) => {
  return (
    <>
      {src && src.length > 0 ? (
        <Card.Img variant="top" src={src} className={styles.img} />
      ) : (
        <AiOutlineUser className={styles.icon} />
      )}
    </>
  );
};

export default ExternalImage;
