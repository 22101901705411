import { memo } from "react";
import TeacherListItem from "./TeacherListItem";
import NotFound from "../NotificationComponent/NotFound";
import ScollLoadList from "./ScollLoadList";
import { isEqual } from "lodash";

const TeacherListGroup = ({
  teachers = [],
  onClick = () => {},
  next = () => {},
  hasMore = false,
  showNotFound = true,
}) => {
  if (teachers.length === 0) {
    return showNotFound ? <NotFound itemName="teachers" /> : <></>;
  }
  const items = teachers.map((teacher) => (
    <TeacherListItem
      key={teacher.id}
      teacher={teacher}
      onClick={() => onClick(teacher)}
    />
  ));

  return (
    <ScollLoadList data={teachers} next={next} hasMore={hasMore}>
      {items}
    </ScollLoadList>
  );
};

const isDataEqual = (prevProps, nextProps) => {
  return (
    isEqual(prevProps.teachers, nextProps.teachers) &&
    isEqual(prevProps.showNotFound, nextProps.showNotFound)
  );
};

export default memo(TeacherListGroup, isDataEqual);
