import { Form } from "react-bootstrap";
import clsx from "clsx";
import styles from "./css/FormGroup.module.css";
import FormLabel from "./FormLabel";

const FormGroup = ({ controlId, label, text, feedback, children }) => (
  <Form.Group className="mb-3" controlId={controlId}>
    <FormLabel>{label}</FormLabel>
    <Form.Text className={styles.text} muted>
      {text}
    </Form.Text>
    {children}
    <Form.Control.Feedback
      type={feedback.type}
      className={clsx(!!feedback.text && "d-block")}
    >
      {feedback.text}
    </Form.Control.Feedback>
  </Form.Group>
);
export default FormGroup;
