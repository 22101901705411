import styles from './RequestItem.module.css';
import SessionItem from "../ScheduleComponent/SessionItem";

const RequestItem = ( { request = {}, onClick: handleOnClick } ) => {
    const onClick = event => {
        if ( handleOnClick ) {
            event.preventDefault();
            handleOnClick( request.id );
        }
    };
    return (
        <div
            className={ styles.root }
            onClick={ onClick }>
            <SessionItem
                teacherSession={ request.teacher_session }
                student={ request.student }
            />
        </div>
    )
};

export default RequestItem;