import { Container, Button } from "react-bootstrap";
import clsx from 'clsx';
import styles from './css/DeleteButton.module.css';

const DeleteButton = ( { deleteButton } ) => (
    <Container className={ clsx( 'text-end', styles.root ) }>
        <Button
            variant="danger"
            type="submit"
            onClick={ deleteButton.handler || ( () => { } ) }
            disabled={ deleteButton.isLoading || false }>
            { deleteButton.text }
        </Button>
    </Container>
);

export default DeleteButton;