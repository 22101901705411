import styles from "./css/SessionItem.module.css";
import {
  toLocalDateTime,
  addLocalDateTime,
  sortAndFilterAvailableTimes,
} from "@mainnote/coremedalteacher";
import clsx from "clsx";
import { isEmpty } from "lodash";
import { BsFillCheckCircleFill } from "react-icons/bs";
import AvatarDisplayItem from "../AvatarComponent/AvatarDisplayItem";
import AvatarTextItem from "../AvatarComponent/AvatarTextItem";
import smartTruncate from "smart-truncate";
import { DayLargeDisplay } from "../../elements";

const SessionItem = ({
  teacherSession = {},
  selected,
  student,
  onClick: handleOnClick,
}) => {
  if (!teacherSession || isEmpty(teacherSession)) {
    return <></>;
  }

  const onClick = (event) => {
    if (handleOnClick) {
      event.preventDefault();
      handleOnClick(teacherSession);
    }
  };
  const { duration, maxAttendees, attendees, teacher, teacher_and_course } =
    teacherSession;

  const availableTimes = sortAndFilterAvailableTimes(
    teacherSession.timeslots?.map(
      (timeslot) => new Date(timeslot.availableTime)
    )
  );
  const startTime = availableTimes.length > 0 ? availableTimes[0] : new Date();

  return (
    <div className={styles.root} onClick={onClick}>
      <DayLargeDisplay
        monthNum={toLocalDateTime(startTime, "MM")}
        monthStr={toLocalDateTime(startTime, "MMM")}
        dayNum={toLocalDateTime(startTime, "DD")}
        yearNum={toLocalDateTime(startTime, "YYYY")}
      />
      <div className={styles.timeWrapper}>
        {student && (
          <AvatarTextItem
            entity={student}
            showField="picture"
            text={smartTruncate(student.fullName, 20)}
          />
        )}
        <div>
          {duration} minutes{" "}
          {availableTimes.length > 0 ? `x ${availableTimes.length}` : ""}
        </div>
        <div>
          {toLocalDateTime(startTime, "HH:mm A")} -{" "}
          {addLocalDateTime(startTime, duration)}
        </div>
        <div>
          {attendees}/{maxAttendees} students
        </div>
        {teacher_and_course && teacher_and_course.course && (
          <AvatarTextItem
            entity={teacher_and_course.course}
            showField="medias"
            text={smartTruncate(teacher_and_course.course.courseName, 20)}
          />
        )}
      </div>

      <AvatarDisplayItem
        className={styles.topright}
        size={12}
        entity={teacher}
      />

      <BsFillCheckCircleFill
        className={clsx(styles.checkmark, !selected && "d-none")}
      />
    </div>
  );
};

export default SessionItem;
