import { Form } from "react-bootstrap";
import styles from "./css/FormControl.module.css";

const FormControl = ({
  controlId,
  label,
  text,
  feedback,
  children,
  onClick = () => {},
}) => (
  <Form.Group className="mb-3" controlId={controlId} onClick={onClick}>
    {label && label.length > 0 && <Form.Label>{label}</Form.Label>}
    {children}
    {text && text.length > 0 && (
      <Form.Text className={styles.text} muted>
        {text}
      </Form.Text>
    )}
    <Form.Control.Feedback
      className={feedback.text && "d-block"}
      type={feedback.type}
    >
      {feedback.text}
    </Form.Control.Feedback>
  </Form.Group>
);

export default FormControl;
