import { DescriptionText, CheckIcon } from "../../elements";
import styles from "./css/TeacherEvaluation.module.scss";
import IconFiveStars from "../IconButtonComponent/IconFiveStars";

const TeacherEvaluation = ({ title, isCertified, rate }) => {
  return (
    <div className={styles.evaluation}>
      <DescriptionText>{title}</DescriptionText>
      {isCertified && <CheckIcon />}
      <IconFiveStars rate={rate} />
    </div>
  );
};

export default TeacherEvaluation;
