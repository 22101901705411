import { Container, Form, Row, Col } from "react-bootstrap";
import FormInput from '../FormControlComponent/FormInput';
import SubmitButton from '../FormControlComponent/SubmitButton';
import DeleteButton from '../FormControlComponent/DeleteButton';
import FormUploadFile from "../FormControlComponent/FormUploadFile";

const StudentProfileForm = ( {
    fields = {},
    entityId,
    entity,
    submitButton = {},
    deleteButton = {}
} ) => {
    return (
        <Container>
            <Form>
                <Row>
                    <Col md>
                        {/* First Name */ }
                        { fields.hasOwnProperty( 'firstName' ) &&
                            <FormInput field={ fields.firstName } />
                        }
                    </Col>
                    <Col md>
                        {/* Middle Name */ }
                        { fields.hasOwnProperty( 'middleName' ) &&
                            <FormInput field={ fields.middleName } />
                        }
                    </Col>
                    <Col md>
                        {/* Last Name */ }
                        { fields.hasOwnProperty( 'lastName' ) &&
                            <FormInput field={ fields.lastName } />
                        }
                    </Col>
                </Row>
                {/* upload student profile image */ }
                { fields.hasOwnProperty( 'profilePicture' ) &&
                    <FormUploadFile field={ fields.profilePicture } />
                }
                {/* introduction */ }
                { fields.hasOwnProperty( 'introduction' ) &&
                    <FormInput
                        field={ fields.introduction }
                        inputProps={ {
                            type: "text",
                            as: "textarea",
                            rows: 2,
                        } }
                    />
                }
                {/* biography */ }
                { fields.hasOwnProperty( 'biography' ) &&
                    <FormInput
                        field={ fields.biography }
                        inputProps={ {
                            type: "text",
                            as: "textarea",
                            rows: 3,
                        } }
                    />
                }
                {/* submit button */ }
                { submitButton &&
                    <SubmitButton submitButton={ submitButton } />
                }
                {/* delete button */ }
                { entityId && entity &&
                    <DeleteButton deleteButton={ deleteButton } />
                }
            </Form>
        </Container>
    );
};
export default StudentProfileForm;