import React, { useEffect, useState, useRef } from "react";
import { useForm, useFiles } from "../../util/common";
import TeacherProfileForm from "../../components/TeacherComponent/TeacherProfileForm";
import {
  useSelector,
  useDispatch,
  onTeacherMeThunk,
  networkStatus,
  networkAction,
  appAction,
  cache,
  selectTeacherProfileById,
  chosenCourseThunk,
  coursesAction,
  simplifyTeacherAndCourses,
  constant,
} from "@mainnote/coremedalteacher";
import { withRouter } from "react-router";
import { useHistory, useParams } from "react-router-dom";
import EditFormWrap from "../../components/FormControlComponent/EditFormWrap";
import FormSearchPrompt from "../../components/FormControlComponent/FormSearchPrompt";
import { BsBook } from "react-icons/bs";
import CourseListGroup from "../../components/ListGroupComponent/CourseListGroup";
import { MessagePrompt } from "../../components";

const getFormValue = (entity) => ({
  teacherId: entity.id,
  firstName: entity.firstName,
  middleName: entity.middleName,
  lastName: entity.lastName,
  title: entity.title,
  biography: entity.biography,
  introduction: entity.introduction,
  teacher_and_courses: entity.teacher_and_courses,
});

const TeacherProfilePage = () => {
  const history = useHistory();

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [modalShow, setModalShow] = useState(null);
  const [modalShowSearch, setModalShowSearch] = useState(false);
  const [chosenCourse, setChosenCourse] = useState(null);
  const [toastShow, setToastShow] = useState(false);
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const isAuth = !!useSelector((state) => state.app.auth.jwt);
  const status = useSelector((state) => state.app.onTeacherMe.status);
  // const coursesStatus = useSelector( state => state.courses.chosenCourse.status );

  const teacherProfileErrors = useSelector(
    (state) => state.app.onTeacherMe.errors
  );
  const coursesErrors = useSelector(
    (state) => state.courses.chosenCourse.errors
  );
  const errors = modalShowSearch ? coursesErrors : teacherProfileErrors;

  const searchText = useSelector(
    (state) => state.courses.chosenCourse.searchText
  );
  const searchCourses = useSelector(
    (state) => state.courses.chosenCourse.courses
  );

  let { teacherId } = useParams();
  if (typeof teacherId === "string") teacherId = parseInt(teacherId);

  const teacher = useSelector((state) =>
    selectTeacherProfileById(state, teacherId)
  );

  let initForm = {};
  if (isPageLoading && teacher) {
    initForm = getFormValue(teacher);
  }
  const { files, onDrop } = useFiles({ cacheItem: cache.teacher });
  const {
    form,
    setForm,
    formErrors,
    setFormErrors,
    submitButton,
    deleteButton,
    setFormField,
  } = useForm({ initForm, status });

  const updateTeacherProfile = () => {
    // No errors! Put any logic here for the form submission!
    let data = {
      action: networkAction.post,
      id: form.teacherId,
      firstName: form.firstName,
      middleName: form.middleName,
      lastName: form.lastName,
      title: form.title,
      biography: form.biography,
      introduction: form.introduction,
    };

    data.teacher_and_courses = simplifyTeacherAndCourses(
      form.teacher_and_courses
    );
    dispatch(onTeacherMeThunk(data));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // reset errors
    dispatch(appAction.resetErrors({ key: "onTeacherMe" }));

    // get our new errors
    const newErrors = findFormErrors();
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setFormErrors(newErrors);
    } else {
      setModalShow(teacherId ? "u" : "c");
    }
  };
  submitButton.handler = handleSubmit;

  const handleDelete = (e) => {
    e.preventDefault();
    setModalShow("d");
  };
  deleteButton.handler = handleDelete;

  const findFormErrors = () => {
    const { firstName, introduction, title } = form;
    const newErrors = {};
    // firstName is required
    if (!firstName || firstName === "")
      newErrors.firstName = "First Name is required!";
    // introduction is required
    if (!introduction || introduction === "")
      newErrors.introduction = "Introduction is required!";
    // title is required
    if (!title || title === "" || title === "Choose title")
      newErrors.title = "Title is required!";

    return newErrors;
  };
  const removeTeacherProfile = () => {
    if (teacher) {
      dispatch(
        onTeacherMeThunk({ id: teacherId, action: networkAction.delete })
      );
    }
    setModalShow(null);
  };

  const handleSearchChange = (searchText) => {
    dispatch(
      coursesAction.setStateByPath({
        path: "chosenCourse.searchText",
        value: searchText,
      })
    );
  };

  const handleCancelSearchPrompt = () => {
    setModalShowSearch(false);
    dispatch(coursesAction.resetChosenSearch());
  };

  const handleSearchButtonClick = (e) => {
    e.preventDefault();
    dispatch(coursesAction.resetChosenSearch());
    setModalShowSearch(true);
  };

  const handleSearchClick = () => {
    const searchQuery = `_q=${searchText}`;
    dispatch(coursesAction.resetChosenSearch());
    dispatch(chosenCourseThunk(searchQuery));
  };

  const handleChosenCourse = (course) => {
    // check if the course had been chosen or not
    const teacher_and_course = form.teacher_and_courses?.find(
      (tc) => tc.course.id === course.id
    );
    if (teacher_and_course) {
      setToastShow(true);
    } else {
      const teacher_and_courses = [
        ...(form.teacher_and_courses || []),
        {
          hourlyRate: 0,
          course: course,
          teacher: teacher,
        },
      ];
      setFormField("teacher_and_courses", teacher_and_courses);
      setModalShowSearch(false);
    }
  };

  const handleTeacherAndCourseChange = (teacher_and_course) => {
    const teacher_and_courses = form.teacher_and_courses.map((tc) =>
      tc.course.id === teacher_and_course.course.id ? teacher_and_course : tc
    );

    setFormField("teacher_and_courses", teacher_and_courses);
  };

  const handleTeacherAndCourseDelete = (teacher_and_course) => {
    setChosenCourse(teacher_and_course);
  };

  const deleteTeacherAndCourseItem = () => {
    if (chosenCourse) {
      const teacher_and_courses = form.teacher_and_courses.filter(
        (tc) => tc.course.id !== chosenCourse.course.id
      );
      setFormField("teacher_and_courses", teacher_and_courses);
    }
    setChosenCourse(null);
  };

  const goBack = (e) => {
    e.preventDefault();
    history.goBack();
  };

  // fields
  const fields = {
    firstName: {
      controlId: "firstNameTeacherProfileForm",
      label: "First Name (required)",
      feedback: { text: formErrors.firstName, type: "invalid" },
      placeholder: "Enter teacher's first name",
      value: form.firstName || "",
      onChange: (e) => setFormField("firstName", e.target.value),
      isInvalid: !!formErrors.firstName,
    },
    middleName: {
      controlId: "middleNameTeacherProfileForm",
      label: "Middle Name",
      feedback: { text: formErrors.middleName, type: "invalid" },
      placeholder: "Enter teacher's middle name",
      value: form.middleName || "",
      onChange: (e) => setFormField("middleName", e.target.value),
      isInvalid: !!formErrors.middleName,
    },
    lastName: {
      controlId: "lastNameTeacherProfileForm",
      label: "Last Name",
      feedback: { text: formErrors.lastName, type: "invalid" },
      placeholder: "Enter teacher's last name",
      value: form.lastName || "",
      onChange: (e) => setFormField("lastName", e.target.value),
      isInvalid: !!formErrors.lastName,
    },
    title: {
      controlId: "titleTeacherProfileForm",
      label: "Title (required)",
      feedback: { text: formErrors.title, type: "invalid" },
      placeholder: "Choose title",
      value: form.title || null,
      onChange: (e) => setFormField("title", e.target.value),
      isInvalid: !!formErrors.title,
      selections: constant.titles,
    },
    profilePicture: {
      controlId: "profilePictureTeacherProfileForm",
      label: "Profile Picture",
      feedback: { text: formErrors.profilePicture, type: "invalid" },
      value: teacher,
      files,
      onDrop,
      isInvalid: !!formErrors.profilePicture,
    },
    biography: {
      controlId: "biographyTeacherProfileForm",
      label: "Biography",
      feedback: { text: formErrors.biography, type: "invalid" },
      placeholder: "Enter teacher's biography",
      value: form.biography || "",
      onChange: (e) => setFormField("biography", e.target.value),
      isInvalid: !!formErrors.biography,
    },
    introduction: {
      controlId: "introductionTeacherProfileForm",
      label: "Introduction (required)",
      feedback: { text: formErrors.introduction, type: "invalid" },
      placeholder: "Enter teacher's introduction",
      value: form.introduction || "",
      onChange: (e) => setFormField("introduction", e.target.value),
      isInvalid: !!formErrors.introduction,
    },
    teacher_and_courses: {
      controlId: "teacherAndCoursesTeacherProfileForm",
      label: "Course to teach",
      feedback: { text: formErrors.teacher_and_courses, type: "invalid" },
      placeholder: "Add a course for teaching",
      value: form.teacher_and_courses || [],
      onClick: handleSearchButtonClick,
      isInvalid: !!formErrors.teacher_and_courses,
      itemProps: {
        label: "Hourly Rate",
        placeholder: "Enter hourly rate",
        onChange: handleTeacherAndCourseChange,
        onDelete: handleTeacherAndCourseDelete,
      },
    },
  };

  // effects
  // on page loading
  useEffect(() => {
    if (isPageLoading) {
      // reset cache
      cache.teacher.files = [];

      // reset errors
      dispatch(appAction.resetErrors({ key: "onTeacherMe" }));

      if (isAuth) {
        dispatch(onTeacherMeThunk());
      }
      setIsPageLoading(false);
    }

    if (!isPageLoading && status === networkStatus.reloaded && teacher) {
      setForm(getFormValue(teacher));
      dispatch(appAction.resetErrors({ key: "onTeacherMe" }));
    }
  }, [isPageLoading, dispatch, isAuth, status, setForm, teacher]);

  // on submit success
  useEffect(() => {
    if (!isPageLoading && status === networkStatus.succeeded) {
      if (history?.location?.state?.fromPath) {
        history.push(history.location.state.fromPath, {
          fromPath: "/teacher-profile",
        });
      } else {
        history.push("/");
      }
    }
  }, [status, history, isPageLoading]);

  // unmounting
  useEffect(() => {
    return () => {
      cache.teacher.files = [];
      dispatch(appAction.resetErrors({ key: "onTeacherMe" }));
      dispatch(coursesAction.resetChosenSearch());
    };
  }, [dispatch]);

  // on search prompt
  useEffect(() => {
    if (modalShowSearch) {
      inputRef.current && inputRef.current.focus();
    }
  }, [modalShowSearch, inputRef]);

  // rendering
  let heading, bodyHeader, body, okHandler;
  switch (modalShow) {
    case "c":
      heading = "Create Teaching Profile";
      bodyHeader = "Are you sure you want to create this teaching profile?";
      body =
        "This teaching profile will be reviewed by our administrators within two business days.";
      okHandler = updateTeacherProfile;
      break;
    case "u":
      heading = "Update Teaching Profile";
      bodyHeader = "Are you sure you want to update this teaching profile?";
      body =
        "Any change is required to be reviewed by our administrators within two business days.";
      okHandler = updateTeacherProfile;
      break;
    case "d":
      heading = "Remove Teaching Profile";
      bodyHeader = "Are you sure you want to remove this teaching profile?";
      body = "This teaching profile will be removed from the system.";
      okHandler = removeTeacherProfile;
      break;
    default:
      okHandler = () => {};
      break;
  }

  return (
    <EditFormWrap
      title={teacherId ? "Update Teaching Profile" : "Create Teaching Profile"}
      errors={errors}
      goBack={goBack}
      messagePromptProps={{
        show: modalShow,
        heading,
        bodyHeader,
        body,
        cancleHandler: () => setModalShow(null),
        okHandler,
      }}
      description={"* Public information will be viewable by everyone!"}
      entityId={teacherId}
      entity={teacher}
      notFoundMessage={"Teaching Profile not found!"}
    >
      <TeacherProfileForm
        fields={fields}
        entityId={teacherId}
        entity={teacher}
        submitButton={submitButton}
        deleteButton={deleteButton}
      />
      <FormSearchPrompt
        show={modalShowSearch}
        searchBarProps={{
          placeholder: fields.teacher_and_courses.placeholder,
          value: searchText,
          handleSearchChange,
          handleSearchClick,
          icon: <BsBook />,
          inputRef: inputRef,
        }}
        cancleHandler={handleCancelSearchPrompt}
        toastProps={{
          onClose: () => setToastShow(false),
          show: toastShow,
        }}
      >
        <CourseListGroup
          courses={searchCourses}
          onClick={handleChosenCourse}
          showNotFound={false}
        />
      </FormSearchPrompt>
      <MessagePrompt
        show={chosenCourse}
        heading="Remove this course"
        bodyHeader="Are you sure you want to remove this course from your teaching profile?"
        body="Removing this course will delete all the session and scheduled sessions."
        cancleHandler={() => setChosenCourse(null)}
        okHandler={deleteTeacherAndCourseItem}
      />
    </EditFormWrap>
  );
};

export default withRouter(TeacherProfilePage);
