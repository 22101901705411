import { Modal } from 'react-bootstrap';
import SearchBar from '../SearchBarComponent/SearchBar';
import styles from './css/FormSearchPrompt.module.css';
import { ToastContainer, Toast } from 'react-bootstrap';

const FormSearchPrompt = ( {
    children,
    show,
    searchBarProps = {},
    cancleHandler = () => { },
    toastProps = { show: false },
} ) => (
    <Modal
        show={ show }
        onHide={ cancleHandler }
        size="lg"
    >
        <Modal.Header closeButton>
            <Modal.Title>
                { searchBarProps.title || 'Search' }
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className={ styles.body }>
            <SearchBar
                { ...searchBarProps }
            />
            <>
                { children }
            </>
            <ToastContainer className="p-3" position="top-center">
                <Toast
                    { ...toastProps }
                    bg="warning"
                    delay={ 1000 }
                    autohide>
                    <Toast.Body>You had chosen this course.</Toast.Body>
                </Toast>
            </ToastContainer>
        </Modal.Body>
    </Modal>
);

export default FormSearchPrompt;